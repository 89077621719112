import React from "react";
import {HomeOrder, HomePPM} from "./Home";
import {Button} from "semantic-ui-react";
import moment from "moment";

interface Day {
    date: number | ''
    ppms: HomePPM[]
    orders: HomeOrder[]
}

export default function HomeCalendar(params: { orders: HomeOrder[], ppms: HomePPM[] }) {
    const [currentDate, setCurrentDate] = React.useState(new Date())
    const [days, setDays] = React.useState<Day[]>([])

    const getPPmCount = ({status, ppms}: { status: "Passed" | "Completed" | "Upcoming" | "Future", ppms: HomePPM[] }) => {
        if (status === "Passed") {
            return ppms.filter((ppm) => ppm.serviceStatus === 'Pending' && ppm.days < 0).length
        } else if (status === "Completed") {
            return ppms.filter((ppm) => ppm.serviceStatus === 'Completed').length
        } else if (status === "Upcoming") {
            return ppms.filter((ppm) => ppm.serviceStatus === 'Pending' && ppm.days >= 0 && ppm.days <= 10).length
        } else {
            return ppms.filter((ppm) => ppm.serviceStatus === 'Pending' && ppm.days > 10).length
        }
    }

    React.useEffect(() => {
        const calculateDaysInMonth = () => {
            const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate()
            const firstDayIndex = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay()

            let aDays: Day[] = []

            for (let dayIndex = 0; dayIndex < firstDayIndex; dayIndex++) {
                aDays = [...aDays, {date: '', ppms: [], orders: []}]
            }

            for (let date = 1; date <= daysInMonth; date++) {
                const indexDate = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), date)).format("ll")
                aDays = [...aDays, {
                    date  : date,
                    ppms  : params.ppms.filter((ppm) => moment(ppm.serviceDate).format("ll") === indexDate),
                    orders: params.orders.filter((order) => moment(order.timeCreated).format("ll") === indexDate)
                }]
            }

            if (aDays.length % 7 > 0) {
                const maxDay = aDays.length % 7
                for (let dayIndex = maxDay + 1; dayIndex <= 7; dayIndex++) {
                    aDays = [...aDays, {date: '', ppms: [], orders: []}]
                }
            }
            setDays(aDays)
        }
        calculateDaysInMonth()
    }, [currentDate])

    return (
        <>
            <div className={'statistics'}>
            </div>

            <div className="calendar">
                <div className="calendar-header">
                    <Button primary size='mini' icon='angle left' compact
                            onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))}/>

                    <div>{currentDate.toLocaleString('default', {month: 'long'})} {currentDate.getFullYear()}</div>

                    <Button primary size='mini' icon='angle right' compact
                            onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))}/>
                </div>

                <div className="days-of-week">
                    {
                        ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                            .map(day => <div key={day} className="day-name">{day}</div>)
                    }
                </div>

                <div className="days-in-month">
                    {
                        days.map((day, index) =>
                            day.date === '' ?
                                <div key={`empty-${index}`} className="day empty"></div>
                                :
                                <div key={index} className={`day ${day.orders.length > 0 || day.ppms.length > 0 ? 'has_data' : ''}`}>
                                    <div className={'ppms'}>
                                        {
                                            getPPmCount({status: 'Passed', ppms: day.ppms}) > 0 &&
                                            <span className={'passed'}>{getPPmCount({status: 'Passed', ppms: day.ppms})}</span>
                                        }
                                        {
                                            getPPmCount({status: 'Completed', ppms: day.ppms}) > 0 &&
                                            <span className={'completed'}>{getPPmCount({status: 'Completed', ppms: day.ppms})}</span>
                                        }
                                        {
                                            getPPmCount({status: 'Upcoming', ppms: day.ppms}) > 0 &&
                                            <span className={'upcoming'}>{getPPmCount({status: 'Upcoming', ppms: day.ppms})}</span>
                                        }
                                        {
                                            getPPmCount({status: 'Future', ppms: day.ppms}) > 0 &&
                                            <span className={'future'}>{getPPmCount({status: 'Future', ppms: day.ppms})}</span>
                                        }
                                    </div>

                                    <div className={'date'}>
                                        {day.date}
                                    </div>


                                    <div className={'orders'}>
                                        {
                                            day.orders.filter((order) => order.workStatus === 'Pending').length > 0 &&
                                            <span className={'pending'}>
                                                {day.orders.filter((order) => order.workStatus === 'Pending').length}
                                            </span>
                                        }

                                        {
                                            day.orders.filter((order) => order.workStatus === 'Rejected').length > 0 &&
                                            <span className={'rejected'}>
                                                {day.orders.filter((order) => order.workStatus === 'Rejected').length}
                                            </span>
                                        }

                                        {
                                            day.orders.filter((order) => order.workStatus === 'Approved').length > 0 &&
                                            <span className={'approved'}>
                                                {day.orders.filter((order) => order.workStatus === 'Approved').length}
                                            </span>
                                        }

                                        {
                                            day.orders.filter((order) => order.workStatus === 'Opened').length > 0 &&
                                            <span className={'opened'}>
                                                {day.orders.filter((order) => order.workStatus === 'Opened').length}
                                            </span>
                                        }

                                        {
                                            day.orders.filter((order) => order.workStatus === 'In Progress').length > 0 &&
                                            <span className={'progress'}>
                                                {day.orders.filter((order) => order.workStatus === 'In Progress').length}
                                            </span>
                                        }

                                        {
                                            day.orders.filter((order) => order.workStatus === 'Closed').length > 0 &&
                                            <span className={'completed'}>
                                                {day.orders.filter((order) => order.workStatus === 'Closed').length}
                                            </span>
                                        }
                                    </div>
                                </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}
