import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";
import SavePPM from "./create/SavePPM";
import React from "react";
import {PPM} from "../../../libs/models/PPM";
import Utilities from "../../../libs/Utilities";

export default function CreatePPM() {
    const staff = useAppSelector(getStaff)

    const [ppm, setPPM] = React.useState<PPM>({
        ppmId: "", branchId: staff.branchId, ppmName: "", ppmAddress: "", serviceFrequency: 'Monthly', startDate: Utilities.getToday(), endDate: "", description: ""
    })

    return (
        <div className={'module_content'}>
            <div className={'row m-0 h-100'}>
                <div className={'col-4 offset-4 px-0 h-100'}>
                    <SavePPM ppm={ppm} update={setPPM}/>
                </div>
            </div>
        </div>
    )
}
