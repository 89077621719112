import {GroupRoles} from "./Types";

export interface AuthUser {
    permissions: (keyof typeof GroupRoles)[]
    userId: string
    companyId: string
    branchId: string
    groupId: string
    firstName: string
    lastName: string
    emailAddress: string
    mobileContact: string
    gender: 'Male' | 'Female'
}

export const initialAuthUser: AuthUser = {
    branchId: "", emailAddress: "", firstName: "", gender: 'Male', groupId: "", lastName: "", mobileContact: "", permissions: [], userId: "", companyId: ""
}
