import {createPortal} from 'react-dom';

import React, {PropsWithChildren, createContext} from "react";

export interface ToastData {
    id?: string
    message: string,
    header?: string
    type?: 'info' | 'success' | 'error'
    timeout?: number
}

interface ToastContextValue {
    infoToast: (params: { message: string, header?: string, timeout?: number }) => void
    errorToast: (params: { message: string, header?: string, timeout?: number }) => void
    successToast: (params: { message: string, header?: string, timeout?: number }) => void
}


const Toast = (props: PropsWithChildren<{ close: () => void, toast: ToastData }>) => {
    const useTimeout = (callback: () => void, delay: number) => {
        const savedCallback = React.useRef(callback);

        // Remember the latest callback if it changes.=
        React.useEffect(() => {
            savedCallback.current = callback;
        }, [callback])

        // Set up the timeout
        React.useEffect(() => {
            // Don't schedule if no delay is specified
            const id = setTimeout(() => savedCallback.current(), delay);
            return () => clearTimeout(id);
        }, [delay])
    }

    useTimeout(props.close, (props.toast.timeout ?? 5) * 1000)

    return (
        <div className={`toast ${props.toast.type}`} onClick={props.close}>
            <div className={'toast_content'}>
                {props.toast.header !== undefined && <span className='toast_header'>{props.toast.header}</span>}
                <span style={{flex: 1}}></span>
                <span onClick={props.close} className="toast_close">x</span>
            </div>
            <div className="toast_message" dangerouslySetInnerHTML={{__html: props.toast.message}}/>
        </div>
    )
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined);

export const useToast = () => {
    const context = React.useContext(ToastContext)
    if (!context) {
        throw new Error('useProgress must be used within a ProgressProvider');
    }
    return context
}

export const ToastProvider = (props: PropsWithChildren<{}>) => {
    const [toasts, setToasts] = React.useState<ToastData[]>([]);

    function createToast(type: 'info' | 'error' | 'success', message: string, header?: string, timeout?: number) {
        const first = ('000' + ((Math.random() * 46656) | 0).toString(36)).slice(-3)
        const second = ('000' + ((Math.random() * 46656) | 0).toString(36)).slice(-3)
        const id = `${first}${second}`
        setToasts((currentToasts) => [...currentToasts, {id, message, header, type, timeout}]);
    }

    const infoToast = (params: { message: string, header?: string, timeout?: number }) => {
        createToast('info', params.message, params.header ?? 'Notice', params.timeout)
    }

    const errorToast = (params: { message: string, header?: string, timeout?: number }) => {
        createToast('error', params.message, params.header ?? 'Error', params.timeout)
    }

    const successToast = (params: { message: string, header?: string, timeout?: number }) => {
        createToast('success', params.message, params.header ?? 'Success', params.timeout)
    }

    const close = (id: string) => {
        setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
    }
    //const contextValue = React.useMemo(() => ({open}), []);

    return (
        <ToastContext.Provider value={{infoToast, errorToast, successToast}}>
            {props.children}

            {
                createPortal(
                    toasts.length === 0 ?
                        <></> :
                        <div className="toasts-wrapper">
                            {toasts.map((toast) => <Toast key={toast.id} close={() => close(toast.id!)} toast={toast}/>)}
                        </div>
                    ,
                    document.body
                )
            }
        </ToastContext.Provider>
    );
};
