import {Dropdown} from "semantic-ui-react";

export type select_type = string | number | boolean | (string | number | boolean)[] | undefined

export interface SelectData {
    text: string
    value: string | number
}

export function MySelect(params: {
    change?: (value: select_type, text: string) => void, onChange: (name: string, value: select_type, text: string) => void,
    label?: string, name: string, value: select_type, style?: Object, className?: string, loading?: boolean, placeholder: string,
    options: Array<{ text: string, value: string | number }>, error?: boolean, message?: string, disabled?: boolean,
    search?: (query: string) => void
}) {
    return (
        <>
            {
                params.label !== undefined && params.label !== "" &&
                <label className={'form_label'}>{params.label}</label>
            }
            <Dropdown
                value={params.value} options={params.options} size="mini" fluid selection search={true} style={params.style}
                className={params.className !== undefined ? params.className : 'mb-3'}
                disabled={params.disabled !== undefined ? params.disabled : false}
                placeholder={params.placeholder ? params.placeholder : 'Select a value'}
                loading={params.loading === undefined ? false : params.loading}

                onSearchChange={(event, data) => {
                    if (params.search !== undefined) {
                        params.search(data.searchQuery)
                    }
                }}
                onChange={((event, data) => {
                    const option = params.options.filter((option) => option.value === data.value)
                    if (params.onChange !== undefined && params.name !== undefined) {
                        params.onChange(params.name, data.value, option.length > 0 ? option[0].text : "")
                    } else if (params.change !== undefined) {
                        params.change(data.value, option.length > 0 ? option[0].text : "")
                    }
                })}/>
        </>
    )
}
