import React, {PropsWithChildren} from 'react';

// Define the shape of the context value
interface ProgressContextValue {
    showProgress: (message: string) => void;
    hideProgress: () => void;
}

// Create the context
const ProgressContext = React.createContext<ProgressContextValue | undefined>(undefined);

// Custom hook to interact with the progress loader
export const useProgress = () => {
    const context = React.useContext(ProgressContext);
    if (!context) {
        throw new Error('useProgress must be used within a ProgressProvider');
    }
    return context
}

// Provider component
export const ProgressProvider = (props: PropsWithChildren<{}>) => {
    const [progress, setProgress] = React.useState({show: false, message: ""});

    const showProgress = (message: string) => {
        setProgress({show: true, message: message})
    }

    const hideProgress = () => {
        setProgress({show: false, message: ""})
    }

    const value: ProgressContextValue = {
        showProgress, hideProgress,
    }

    return (
        <ProgressContext.Provider value={value}>
            {props.children}
            {
                progress.show &&
                <div className={'progress_container'}>
                    <div className='progress_window'>
                        <div className="progress_spinner"/>
                        <div className='progress_text'>{progress.message}</div>
                    </div>
                </div>
            }
        </ProgressContext.Provider>
    )
}
