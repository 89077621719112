import moment from "moment";
import {ChangeEvent} from "react";
import {InputFile} from "./models/InputFile";

export default class Utilities {
    //static BASE_URL = "http://127.0.0.1:8005/"
    static BASE_URL = "https://oms-api.wilsofts.com/"

    static downloadFile(dataUrl: string) {
        // Construct the 'a' element
        let link = document.createElement("a")
        link.target = "_blank"

        // Construct the URI
        link.href = `${Utilities.BASE_URL}${dataUrl}`
        document.body.appendChild(link)
        link.click()

        // Cleanup the DOM
        document.body.removeChild(link)
    }

    static getToday = () => {
        return moment().format("YYYY-MM-DD")
    }

    public static formatContact(contact: string): string {
        return contact.replace(/\D/g, '')
    }

    public static isNumberValid(value: string): boolean {
        return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
    }

    public static invalid_email(email: string): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !regex.test(email.trim());
    }

    public static invalidName(name: string): boolean {
        const regex = /^[a-zA-Z]{2,30}$/;
        return !regex.test(name);
    }

    public static invalid_address(name: string): boolean {
        const regex = /^[a-zA-Z\d ]{2,100}$/;
        return !regex.test(name);
    }

    public static comma_number(value: number | string, pre ?: string, post ?: string): string {
        if (!this.isNumberValid(value.toString())) {
            return value.toString()
        }
        const prefix = pre === undefined ? 'shs ' : pre
        const postfix = post === undefined ? '' : post
        return prefix + Utilities.roundToTwo(value as number, "2").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + postfix
    }

    public static commaInput(value: string | number): string {
        let number = Utilities.stripCommas(value)
        if (Utilities.isNumberValid(number) && number.substr(number.length - 1) !== ".") {
            return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
            return value.toString()
        }
    }

    public static stripCommas(number: string | number) {
        return number.toString().replace(/[^\d.-]/g, '').trim()
    }

    static roundToTwo = (num: number, decimals?: string): number => {
        if (decimals === undefined) {
            decimals = "2";
        }
        return +(Math.round(parseFloat(num + `e+${decimals}`)) + `e-${decimals}`);
    }

    public static formatLicense = (license: string | number) => {
        const rawValue = license.toString().replace(/\s+/g, '');
        return rawValue.match(/.{1,4}/g)?.join(' ') || ''
    }

    public static readFileInput(event: ChangeEvent<HTMLInputElement>) {
        return new Promise<InputFile>((resolve, reject) => {
            const files = event.target.files;
            if (files !== null && files.length > 0) {
                const file: File | null = files.item(0);
                const reader = new FileReader();
                reader.readAsDataURL(file as File);
                reader.onload = function (event) {
                    const target = event.target;
                    if (target !== null) {
                        resolve(new InputFile(target.result as string, file as File))
                    } else {
                        reject(null)
                    }
                };
                reader.onerror = error => reject(error);
            } else {
                reject(null)
            }
        });
    }
}
