import {Button, Icon} from "semantic-ui-react";
import React from "react";

export default function FormButtons(params: {
    left: { text?: string, click: () => void },
    right: { text?: string, click: () => void }
}) {
    return (
        <div className={'form_buttons'}>
            <div className={'row mx-0'}>
                <div className={'col-6 ps-0 pe-1'}>
                    <Button icon labelPosition='left' fluid={true} secondary onClick={() => params.left.click()} size={'small'}>
                        <Icon name='close'/>{params.left.text ?? 'Clear Data'}
                    </Button>
                </div>

                <div className={'col-6 ps-1 pe-0'}>
                    <Button icon labelPosition='left' primary fluid={true} onClick={() => params.right.click()} size={'small'}>
                        <Icon name='save'/>{params.right.text ?? 'Save Data'}
                    </Button>
                </div>
            </div>
        </div>
    )
}
