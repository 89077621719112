import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store"
import {AuthUser, initialAuthUser} from "../../models/AuthUser";
import {GroupRoles} from "../../models/Types";

type UserState = {
    user: AuthUser
    counter: number
    bearerToken: string
}

const initialState: UserState = {
    user: initialAuthUser, counter: 0, bearerToken: ''
}

const userSlice = createSlice({
    name    : 'user', initialState: initialState,
    reducers: {
        loginUser      : (state: Draft<typeof initialState>, action: PayloadAction<{ user: AuthUser, bearerToken: string }>) => {
            state.user = action.payload.user
            state.bearerToken = action.payload.bearerToken
        },
        logoutUser     : (state: Draft<typeof initialState>) => {
            state.user = initialAuthUser
            state.bearerToken = ""
        },
        updateBearer   : (state: Draft<typeof initialState>, action: PayloadAction<string>) => {
            state.bearerToken = action.payload
        },
        updateCounter  : (state: Draft<typeof initialState>, action: PayloadAction<number>) => {
            state.counter = action.payload
        },
        updateUserRoles: (state: Draft<typeof initialState>, action: PayloadAction<(keyof typeof GroupRoles)[]>) => {
            state.user.permissions = action.payload
        }
    },
});

// Selectors
export const getStaff = (state: RootState) => state.user.user
export const getCounter = (state: RootState) => state.user.counter
export const getBearer = (state: RootState) => state.user.bearerToken

// Reducers and actions
export const {loginUser, logoutUser, updateCounter, updateBearer, updateUserRoles} = userSlice.actions

export default userSlice.reducer
