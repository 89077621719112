import {Pages} from "../../../libs/models/Types";
import {WorkOrderSite} from "../../../libs/models/WorkOrderSite";
import {BranchData} from "../../../libs/models/Branch";
import React from "react";
import {WorkOrderData} from "../../../libs/models/WorkOrder";
import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";
import {Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";
import {MySelect} from "../../../libs/components/MySelect";
import WorkOrderSites from "./WorkOrderSites";
import Requests from "../../../libs/Requests";
import moment from "moment";
import WorkOrderDetail from "./utils/WorkOrderDetail";

export default function ListWorkOrder(params: { page: Pages, sites: WorkOrderSite[], updateSite: (site: WorkOrderSite) => void, branches: BranchData[] }) {
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [showSites, setShowSites] = React.useState(false)
    const [search, setSearch] = React.useState({workStatus: 'All', branchId: staff.branchId});
    const [workOrders, setWorkOrders] = React.useState<WorkOrderData[]>([])
    const [workOrder, setWorkOrder] = React.useState<WorkOrderData | undefined>(undefined)

    const loadWorkOrders = () => {
        showProgress("Loading work orders, please wait")
        Requests.getWorkOrders({branchId: search.branchId, workStatus: search.workStatus})
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setWorkOrders(response.data.orders)
                    successToast({message: 'Work orders data loaded successfully'})
                } else {
                    errorToast({message: response.data.message ?? 'Error loading work orders, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
                errorToast({message: 'Error loading work orders, please retry'})
            })
    }

    React.useEffect(() => {
        if (params.page === 'Approve WorkOrders') {
            setSearch({...search, workStatus: 'Pending'})
        } else if (params.page === 'WorkOrders-Approved') {
            setSearch({...search, workStatus: 'Approved'})
        } else if (params.page === 'WorkOrders-InProgress') {
            setSearch({...search, workStatus: 'In Progress'})
        } else if (params.page === 'WorkOrders-Closed') {
            setSearch({...search, workStatus: 'Closed'})
        } else if (params.page === 'WorkOrders-Open') {
            setSearch({...search, workStatus: 'Opened'})
        } else if (params.page === 'WorkOrders-All') {
            setSearch({...search, workStatus: 'All'})
        }
    }, [params.page])

    React.useEffect(() => {
        loadWorkOrders()
    }, [search.workStatus])

    return (
        <>
            <WorkOrderSites show={showSites} sites={params.sites} close={() => setShowSites(false)} updateSite={params.updateSite}/>
            <WorkOrderDetail
                close={() => setWorkOrder(undefined)} workOrder={workOrder}
                update={(aOrder) => {
                    setWorkOrders(workOrders.map((bOrder) => bOrder.workOrderId === aOrder.workOrderId ? aOrder : bOrder))
                    if (aOrder.workStatus === 'Approved') {
                        setWorkOrder(undefined)
                    } else {
                        setWorkOrder(aOrder)
                    }
                }}/>

            <div className={'filter_container'}>
                <div className={'filters'}>
                    <div>
                        <MySelect
                            name={"branchId"} value={search.branchId} placeholder={"Select branch name"} className={'mb-0'}
                            onChange={(name, value) => setSearch({...search, [name]: value as string})}
                            options={[{text: 'View all branches', value: ''}, ...params.branches.map((branch) => ({text: branch.branchName, value: branch.branchId}))]}/>
                    </div>
                </div>

                <div className={'buttons'}>
                    <Button primary icon labelPosition='left' onClick={() => setShowSites(true)}>
                        <Icon name='sitemap'/>Sites
                    </Button>

                    <Button icon labelPosition='left' primary onClick={loadWorkOrders}>
                        <Icon name='search'/>Search
                    </Button>
                </div>
            </div>

            <div className={'module_content'}>
                <div className={'row m-0 h-100'}>
                    <div className={'col-12 px-0 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '40px'}}></TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>Order No</TableHeaderCell>
                                        <TableHeaderCell style={{width: '130px'}}>Branch Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>Site Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '130px'}}>Order Date</TableHeaderCell>
                                        <TableHeaderCell style={{width: '60px'}} textAlign={'center'}>Days</TableHeaderCell>
                                        <TableHeaderCell style={{width: '60px'}}>Level</TableHeaderCell>
                                        <TableHeaderCell style={{width: '140px'}}>Category</TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>Technician</TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>Status</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        workOrders.map((order) =>
                                            <TableRow key={order.workOrderId}>
                                                <TableCell style={{width: '40px'}} textAlign={'center'}>
                                                    <Button primary size='mini' icon='edit' compact onClick={() => setWorkOrder(order)}/>
                                                </TableCell>
                                                <TableCell style={{width: '80px'}}>{order.orderNo}</TableCell>
                                                <TableCell style={{width: '130px'}}>
                                                    {params.branches.filter((branch) => branch.branchId === order.branchId)[0]?.branchName}
                                                </TableCell>
                                                <TableCell style={{width: '150px'}}>{order.siteName}</TableCell>
                                                <TableCell style={{width: '130px'}}>{moment(order.timeCreated).format("lll")}</TableCell>
                                                <TableCell style={{width: '60px'}} textAlign={'center'}>{order.days}</TableCell>
                                                <TableCell style={{width: '60px'}}>{order.workLevel}</TableCell>
                                                <TableCell style={{width: '140px'}}>
                                                    {order.assetCategory === 'Other' ? order.assetCategoryOther : order.assetCategory}
                                                </TableCell>
                                                <TableCell style={{width: '150px'}}></TableCell>
                                                <TableCell style={{width: '80px'}}>{order.workStatus}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
