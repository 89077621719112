import React from "react";
import {useToast} from "../../libs/components/providers/ToastProvider";
import {useProgress} from "../../libs/components/providers/ProgressProvider";
import MyInput from "../../libs/components/MyInput";
import {Button} from "semantic-ui-react";
import Requests from "../../libs/Requests";
import ChangePassword from "./ChangePassword";
import {useAppDispatch} from "../../libs/store/hooks";
import {loginUser} from "../../libs/store/slices/userSlice";

export default function Login() {
    const dispatch = useAppDispatch()
    const {errorToast, successToast, infoToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [changePassword, setChangePassword] = React.useState(false)
    const [login, setLogin] = React.useState({credential: '', password: ''})
    const handleChange = (name: string, value: string) => {
        setLogin({...login, [name]: value})
    }

    const proceedLogin = () => {
        if (login.credential.trim().length < 5) {
            errorToast({message: 'Please enter a valid email address or phone number'})
        } else if (login.password.length < 6) {
            errorToast({message: 'Please enter a valid password'})
        } else {
            showProgress("Logging in, please wait")
            Requests.loginUser({credential: login.credential.trim(), loginPassword: login.password})
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: `Successfully logged in`})
                        dispatch(loginUser({user: response.data.user, bearerToken: response.data.bearerToken}))
                        if (response.data.changePassword === 'Yes') {
                            setChangePassword(true)
                        }
                    } else {
                        errorToast({message: response.data.message ?? 'Could not change password, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not login user, please retry'})
                })
        }
    }

    return (
        <>
            <ChangePassword close={() => setChangePassword(false)} show={changePassword}/>

            <div className="login_container">
                <div className={'login_form'}>
                    <img src={require('../../libs/styles/images/logo.png')} alt="logo"/>
                    <h1>Order Management System</h1>
                    <h2>Sign In</h2>

                    <div className="form_container">
                        <div className={'form_input'}>
                            <MyInput name={'credential'} placeholder={'Enter email address or contact'} label={'Email or Phone Number'}
                                     value={login.credential} onChange={handleChange}/>

                            <MyInput name={'password'} placeholder={'Enter account password'} label={'Account Password'} className={'mb-0'} type={'password'}
                                     value={login.password} onChange={handleChange}/>
                        </div>

                        <div className={'form_buttons'}>
                            <Button fluid={true} primary onClick={proceedLogin} content={'Proceed Login'}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
