import React from "react";
import Requests from "../../libs/Requests";
import {useProgress} from "../../libs/components/providers/ProgressProvider";
import {useToast} from "../../libs/components/providers/ToastProvider";
import MyInput from "../../libs/components/MyInput";
import {Modal} from "semantic-ui-react";
import FormButtons from "../../libs/components/FormButtons";
import {useAppDispatch} from "../../libs/store/hooks";
import {updateBearer} from "../../libs/store/slices/userSlice";

export default function ChangePassword(params: { show: boolean, close: () => void }) {
    const dispatch = useAppDispatch()
    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [changePassword, setChangePassword] = React.useState({loginPassword: '', newPassword: '', confirmPassword: ''})
    const handleChange = (name: string, value: string) => {
        setChangePassword({...changePassword, [name]: value})
    }

    const updatePassword = () => {
        if (changePassword.loginPassword.length < 6) {
            errorToast({message: 'Current password must be at least 6 characters'})
        } else if (changePassword.loginPassword === changePassword.newPassword) {
            errorToast({message: 'New password must be the same as the old password'})
        } else if (changePassword.loginPassword.length < 6) {
            errorToast({message: 'New password must be at least 6 characters'})
        } else if (changePassword.loginPassword !== changePassword.confirmPassword) {
            errorToast({message: 'Passwords do not match'})
        } else {
            showProgress("Resetting user password, please wait")
            Requests.changePassword({newPassword: changePassword.newPassword, loginPassword: changePassword.loginPassword})
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: `Password has been changed successfully`})
                        params.close()
                    } else {
                        errorToast({message: response.data.message ?? 'Could not change password, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not change password, please retry'})
                })
        }
    }

    React.useEffect(() => {
        setChangePassword({loginPassword: '', confirmPassword: '', newPassword: ''});
    }, [params.show])

    return (
        <Modal open={params.show} basic size='mini' centered={false}>
            <div className="modal_div">
                <div className="form_header">{`Change Password`}</div>
                <div className="form_container">
                    <div className={'form_input'}>
                        <MyInput name={'loginPassword'} placeholder={'Enter current login password'} type={'password'}
                                 value={changePassword.loginPassword} label={'Current Password'} onChange={handleChange}/>

                        <MyInput name={'newPassword'} placeholder={'Enter new login password'} type={'password'}
                                 value={changePassword.newPassword} label={'New Password'} onChange={handleChange}/>

                        <MyInput name={'confirmPassword'} placeholder={'Confirm new login password'} className={'mb-0'} type={'password'}
                                 value={changePassword.confirmPassword} label={'Confirm New Password'} onChange={handleChange}/>
                    </div>

                    <FormButtons
                        left={{
                            text: 'Close Window', click: () => {
                                params.close()
                                dispatch(updateBearer(""))
                            }
                        }}
                        right={{click: updatePassword, text: 'Change Password'}}/>
                </div>
            </div>
        </Modal>
    )
}
