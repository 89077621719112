import {Input} from "semantic-ui-react";
import React from "react";

export default function MyInput(params: {
    name: string, label?: string, placeholder: string, className?: string, type?: React.HTMLInputTypeAttribute,
    value: string, onChange: (name: string, value: string) => void
}) {
    return (
        <div className={params.className ?? 'mb-3'}>
            {params.label && <label className={'form_label'}>{params.label}</label>}

            <Input placeholder={params.placeholder} size={'mini'} type={params.type}
                   onChange={(_event, data) => params.onChange(params.name, data.value)} fluid={true} value={params.value}/>
        </div>
    )
}
