import {PPMData, PPMService} from "../../../../libs/models/PPM";
import {Button, Icon, Modal, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import React from "react";
import moment from "moment";
import {UserData} from "../../../../libs/models/User";
import Requests from "../../../../libs/Requests";
import {useToast} from "../../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../../libs/components/providers/ProgressProvider";
import {MyDateInput} from "../../../../libs/components/MyDateInput";
import Utilities from "../../../../libs/Utilities";
import {MySelect} from "../../../../libs/components/MySelect";
import FormTextArea from "../../../../libs/components/FormTextArea";

export default function PPMServices({ppmData, close, users, update}: { ppmData?: PPMData, close: () => void, users: UserData[], update: (ppmData: PPMData) => void }) {
    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const initialService: PPMService = {
        technicianId: "", ppmServiceId: "", serviceDate: "", serviceNotes: "",
        timeUpdated : "", userId: "", technicianName: '', serviceStatus: 'Pending'
    }
    const [service, setService] = React.useState<PPMService>(initialService)
    const handleService = (name: string, value: string) => {
        setService({...service, [name]: value})
    }

    const savePPmService = () => {
        if (service.serviceStatus !== 'Pending' && service.technicianId === '') {
            errorToast({message: 'Select technician who made service'})
        } else if (service.serviceNotes.trim().length < 10) {
            errorToast({message: 'Enter a valid notes of service'})
        } else {
            showProgress("Saving ppm service, please wait")
            Requests.savePPMService({
                ppmId        : ppmData!!.ppmId,
                ppmServiceId : service.ppmServiceId,
                serviceDate  : service.serviceDate,
                serviceNotes : service.serviceNotes.trim(),
                serviceStatus: service.serviceStatus,
                technicianId : service.technicianId,
            })
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        let aServices: PPMService[] = [];
                        (response.data.services as { serviceDate: string, ppmServiceId: string }[]).forEach((aService) => {
                            aServices = [...aServices, {...initialService, serviceDate: aService.serviceDate, ppmServiceId: aService.ppmServiceId}]
                        })
                        successToast({message: 'PPM Service has been saved successfully'})
                        update({
                            ...ppmData!!,
                            services: [...ppmData!!.services.map((aService) => aService.ppmServiceId === service.ppmServiceId ? service : aService), ...aServices]
                        })
                    } else {
                        errorToast({message: response.data.message ?? 'Could not save ppm service, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not save ppm service, please retry'})
                })
        }
    }

    React.useEffect(() => {
        if (ppmData === undefined) {
            setService(initialService);
        } else {
            setService(ppmData.services[ppmData.services.length - 1]);
        }
    }, [ppmData])

    return (
        <>
            {
                ppmData !== undefined &&
                <Modal open={true} basic size='small' centered={false}>
                    <div className="modal_div full">
                        <div className="form_header">PPM Services for {ppmData.ppmName}</div>

                        <div style={{flex: 1}}>
                            <div className={'table_container'}>
                                <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell style={{width: '70px'}}>Service Date</TableHeaderCell>
                                            <TableHeaderCell style={{width: '70px'}}>Status</TableHeaderCell>
                                            <TableHeaderCell style={{width: '120px'}}>Technician Name</TableHeaderCell>
                                            <TableHeaderCell style={{width: '230px'}}>Service Notes</TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>

                                    <TableBody>
                                        {
                                            ppmData.services.map((aService) =>
                                                <TableRow key={aService.serviceDate} onClick={() => setService(aService)}>
                                                    <TableCell style={{width: '70px'}}>{moment(aService.serviceDate).format("ll")}</TableCell>
                                                    <TableCell style={{width: '70px'}}>{aService.serviceStatus}</TableCell>
                                                    <TableCell style={{width: '120px'}}>{aService.technicianName}</TableCell>
                                                    <TableCell style={{width: '230px'}}>{aService.serviceNotes}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>

                        <div style={{height: 'auto'}} className={'mt-2'}>
                            <div className="form_container">
                                <div className={'form_input'}>
                                    <div className={'row mx-0'}>
                                        <div className={'col-6 ps-0 pe-1'}>
                                            <MyDateInput name={'serviceDate'} placeholder={'Select service date'} value={service.serviceDate} label={"Date Serviced"}
                                                         change={handleService} maxDate={Utilities.getToday()} minDate={ppmData.nextDate}/>
                                        </div>

                                        <div className={'col-6 ps-1 pe-0'}>
                                            <MySelect
                                                name={'serviceStatus'} placeholder={'Select service status'} value={service.serviceStatus} label={'Service status'}
                                                onChange={(name, value) => setService({...service, [name]: value as string})}
                                                options={[{text: 'Pending', value: 'Pending'}, {text: 'Completed', value: 'Completed'}]}/>
                                        </div>

                                        <div className={'col-12 px-0'}>
                                            <MySelect
                                                name={'technicianId'} placeholder={'Select technician'} value={service.technicianId} label={'Technician Name'}
                                                onChange={(name, value, text) => setService({...service, [name]: value as string, technicianName: text})}
                                                options={[
                                                    {text: 'Select a technician', value: ''},
                                                    ...users.map((user) => ({text: `${user.lastName} ${user.firstName}`, value: user.userId}))
                                                ]}/>
                                        </div>

                                        <div className={'col-12 px-0'}>
                                            <FormTextArea label={'Service Notes'} name={'serviceNotes'} onChange={handleService}
                                                          placeholder={'Enter service notes here'} rows={4} value={service.serviceNotes}/>
                                        </div>
                                    </div>
                                </div>

                                <div className={'form_buttons'}>
                                    <div className={'row mx-0 justify-content-center'}>
                                        <div className={'col-4 ps-0 pe-1'}>
                                            <Button icon labelPosition='left' fluid={true} secondary onClick={() => close()} size={'small'}>
                                                <Icon name='close'/>{'Close Window'}
                                            </Button>
                                        </div>

                                        <div className={'col-4 ps-1 pe-0'}>
                                            <Button icon labelPosition='left' primary fluid={true} onClick={() => savePPmService()}
                                                    size={'small'} disabled={service.timeUpdated !== ''}>
                                                <Icon name='save'/>{'Save Service'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}
