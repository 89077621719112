import {WorkOrderSite} from "../../../libs/models/WorkOrderSite";
import {WorkOrder} from "../../../libs/models/WorkOrder";
import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";
import React from "react";
import FormButtons from "../../../libs/components/FormButtons";
import {MySelect} from "../../../libs/components/MySelect";
import MyInput from "../../../libs/components/MyInput";
import FormTextArea from "../../../libs/components/FormTextArea";
import Utilities from "../../../libs/Utilities";
import {InputFile} from "../../../libs/models/InputFile";
import Requests from "../../../libs/Requests";
import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";

export default function CreateWorkOrder(params: { sites: WorkOrderSite[] }) {
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const initialOrder: WorkOrder = {
        assetCategory: "Other", assetCategoryOther: '', branchId: staff.branchId, description: "", estimatedCost: "", orderNo: "",
        siteId       : "", timeCreated: "", timeUpdated: "", workImage: new InputFile(""), workLevel: "Low", workOrderId: "", workStatus: 'Pending',
    }

    const [order, setOrder] = React.useState<WorkOrder>(initialOrder)
    const handleChange = (name: string, value: string) => {
        setOrder({...order, [name]: value})
    }

    const saveOrder = () => {
        showProgress("Saving work order, please wait")
        Requests.saveWorkOrder({
            assetCategoryOther: order.assetCategory === 'Other' ? order.assetCategoryOther : '',
            description       : order.description.trim(),
            orderNo           : order.orderNo.trim(),
            workImage         : order.workImage.file,
            assetCategory     : order.assetCategory,
            workOrderId       : order.workOrderId,
            workLevel         : order.workLevel,
            branchId          : order.branchId,
            siteId            : order.siteId,
        })
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    let aOrder: WorkOrder = {...order, workOrderId: response.data.workOrderId}
                    if (response.data.hasOwnProperty("workImage")) {
                        aOrder = {...aOrder, workImage: new InputFile(`${Utilities.BASE_URL}${response.data.workImage}`)}
                    }
                    setOrder(aOrder)
                    successToast({message: 'Work order data has been saved successfully'})
                } else {
                    errorToast({message: response.data.message ?? 'Could not save user, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
            })
    }

    return (
        <div className={'module_content'}>
            <div className={'row m-0 h-100'}>
                <div className={'col-6 offset-3 px-0 h-100'}>
                    <div className={'form_container'}>
                        <div className={'form_input'}>
                            {/*
                               estimatedCost: "", : "",
              : "",
                            */}
                            <MyInput name={'orderNo'} placeholder={'Enter work oder number'} value={order.orderNo} onChange={handleChange} label={'Work Order Number'}/>

                            <MySelect
                                name={"workLevel"} value={order.workLevel} placeholder={"Select branch name"} label={'Work Level'}
                                onChange={(name, value) => setOrder({...order, [name]: value as string})}
                                options={[{text: 'Low', value: 'Low'}, {text: 'Medium', value: 'Medium'}, {text: 'High', value: 'High'}]}/>

                            <MySelect
                                name={"siteId"} value={order.siteId} placeholder={"Select work order site"} label={'Work Order Location'}
                                onChange={(name, value) => setOrder({...order, [name]: value as string})}
                                options={[{text: 'Select work location', value: ''}, ...params.sites.map((site) => ({text: site.siteName, value: site.siteId}))]}/>

                            <MySelect
                                name={"assetCategory"} value={order.assetCategory} placeholder={"Select asset category"} label={'Asset Category'}
                                onChange={(name, value) => setOrder({...order, [name]: value as string})}
                                options={['Plumbing', 'Electrical', 'Floor', 'Ceiling', 'Wall', 'Other'].map((category) => ({text: category, value: category}))}/>

                            {
                                order.assetCategory === 'Other' &&
                                <MyInput name={'assetCategoryOther'} placeholder={'Enter category of work oder'}
                                         value={order.assetCategoryOther} onChange={handleChange}/>
                            }

                            <FormTextArea name={"description"} onChange={handleChange} placeholder={"Enter description of work order"}
                                          rows={6} value={order.description} label={'Description of work order'}/>

                            <div>
                                <img src={order.workImage.file_src} alt='' style={{maxWidth: '400px', height: 'auto'}}/>
                            </div>

                            <div className="custom-file-input">
                                <input type="file" id="fileInput" className="file-input" accept="image/*" multiple={false}
                                       onChange={(event) => {
                                           Utilities.readFileInput(event)
                                               .then((response) => {
                                                   setOrder({...order, workImage: response});
                                                   (document.getElementById("fileInput") as HTMLInputElement).value = "";
                                               })
                                               .catch((error) => {
                                                   console.log(error)
                                               })
                                       }}
                                />
                                <label htmlFor="file" className="file-label">Choose a file</label>
                            </div>
                        </div>

                        <FormButtons left={{click: () => setOrder(initialOrder)}} right={{click: saveOrder}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
