import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";
import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";
import {WorkOrderSite} from "../../../libs/models/WorkOrderSite";
import React from "react";
import {Button, Modal, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import MyInput from "../../../libs/components/MyInput";
import FormButtons from "../../../libs/components/FormButtons";
import Requests from "../../../libs/Requests";

export default function WorkOrderSites(params: { show: boolean, sites: WorkOrderSite[], close: () => void, updateSite: (site: WorkOrderSite) => void }) {
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const initialSite: WorkOrderSite = {companyId: staff.companyId, siteId: "", siteName: ""}
    const [workOrderSite, setWorkOrderSite] = React.useState(initialSite)

    const handleChange = (name: string, value: string) => {
        setWorkOrderSite({...workOrderSite, [name]: value})
    }

    const saveWorkOrderSite = () => {
        if (workOrderSite.siteName.trim().length < 3) {
            errorToast({message: 'Enter a valid work order site name'})
        } else {
            showProgress("Saving work order site, please wait")
            Requests.saveWorkOrderSite({companyId: workOrderSite.companyId, siteId: workOrderSite.siteId, siteName: workOrderSite.siteName})
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: 'Work order site has been saved successfully'})
                        setWorkOrderSite({...workOrderSite, siteId: response.data.siteId})
                        params.updateSite({...workOrderSite, siteId: response.data.siteId})
                    } else {
                        errorToast({message: response.data.message ?? 'Could not save work order site, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not save work order site, please retry'})
                })
        }
    }

    React.useEffect(() => {
        setWorkOrderSite(initialSite)
    }, [params.show])

    return (
        <Modal open={params.show} basic size='small' centered={false}>
            <div className="modal_div" style={{height: '50vh'}}>
                <div className="form_header">Work Order Sites</div>

                <div className={'row mx-0'} style={{flex: 1}}>
                    <div className={'col-6 ps-0 pe-1 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '40px'}}></TableHeaderCell>
                                        <TableHeaderCell style={{width: '200px'}}>Site Name</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        params.sites.map((aSite) =>
                                            <TableRow key={aSite.siteId}>
                                                <TableCell style={{width: '40px'}} textAlign={'center'}>
                                                    <Button primary size='mini' icon='edit' compact onClick={() => setWorkOrderSite(aSite)}/>
                                                </TableCell>
                                                <TableCell style={{width: '200px'}}>{aSite.siteName}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className={'col-6 ps-0 pe-1 h-100'}>
                        <div className="form_container">
                            <div className={'form_input'}>
                                <MyInput name={'siteName'} placeholder={'Enter site name'} label={'Site Name'}
                                         value={workOrderSite.siteName} onChange={handleChange}/>
                            </div>

                            <FormButtons
                                left={{
                                    text : workOrderSite.siteId === '' ? 'Close Window' : 'Clear Data',
                                    click: () => workOrderSite.siteId === '' ? params.close() : setWorkOrderSite(initialSite)
                                }}
                                right={{click: saveWorkOrderSite}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
