import {User} from "../../../../libs/models/User";
import {useToast} from "../../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../../libs/components/providers/ProgressProvider";
import React from "react";
import {Modal} from "semantic-ui-react";
import FormButtons from "../../../../libs/components/FormButtons";
import MyInput from "../../../../libs/components/MyInput";
import Requests from "../../../../libs/Requests";

export default function ResetPassword(params: { user: User, show: boolean, close: () => void }) {
    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [reset, setReset] = React.useState({loginPassword: '', confirmPassword: ''})
    const handleChange = (name: string, value: string) => {
        setReset({...reset, [name]: value})
    }

    const resetPassword = () => {
        if (reset.loginPassword.length < 6) {
            errorToast({message: 'User password must be at least 6 characters'})
        } else if (reset.loginPassword !== reset.confirmPassword) {
            errorToast({message: 'Passwords do not match'})
        } else {
            showProgress("Resetting user password, please wait")
            Requests.resetPassword({userId: params.user.userId, loginPassword: reset.loginPassword})
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: `Password of ${params.user.lastName} ${params.user.firstName} has been reset successfully`})
                        params.close()
                    } else {
                        errorToast({message: response.data.message ?? 'Could not reset user password, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not reset user password, please retry'})
                })
        }
    }

    React.useEffect(() => {
        setReset({loginPassword: '', confirmPassword: ''});
    }, [params.show])

    return (
        <Modal open={params.show} basic size='mini' centered={false}>
            <div className="modal_div">
                <div className="form_header">{`Reset password of ${params.user.lastName} ${params.user.firstName}`}</div>
                <div className="form_container">
                    <div className={'form_input'}>
                        <MyInput name={'loginPassword'} placeholder={'Enter new login password'} type={'password'}
                                 value={reset.loginPassword} label={'Login Password'} onChange={handleChange}/>

                        <MyInput name={'confirmPassword'} placeholder={'Confirm new login password'} className={'mb-0'} type={'password'}
                                 value={reset.confirmPassword} label={'Confirm Password'} onChange={handleChange}/>
                    </div>

                    <FormButtons
                        left={{text: 'Close Window', click: params.close}}
                        right={{click: resetPassword, text: 'Reset Password'}}/>
                </div>
            </div>
        </Modal>
    )
}
