import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store"

type DailyState = {
    current_date: string
}

const initialState: DailyState = {
    current_date: ''
}

const systemSlice = createSlice({
    name: 'system', initialState: initialState,
    reducers: {
        updateDate: (state: Draft<typeof initialState>, action: PayloadAction<string>) => {
            state.current_date = action.payload
        },
    },
});

// Selectors
export const getCurrentDate = (state: RootState) => state.system.current_date

// Reducers and actions
export const {updateDate} = systemSlice.actions

export default systemSlice.reducer
