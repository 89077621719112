import {HomeOrder, HomePPM} from "./Home";
import React from "react";
import {Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import moment from "moment";

export default function HomeStatistics(params: { orders: HomeOrder[], ppms: HomePPM[] }) {
    const [tab, setTab] = React.useState<{ group: 'ppm' | 'order', active: string }>({group: 'ppm', active: 'Passed'})

    const getPPmClass = (ppm: HomePPM) => {
        if (ppm.days < 0) {
            if (ppm.serviceStatus === 'Completed') {
                return 'completed'
            } else {
                return 'passed'
            }
        } else {
            if (ppm.days <= 10) {
                return 'upcoming'
            } else {
                return 'future'
            }
        }
    }

    return (
        <>
            <div className={'statistics_header'}>PPM Statistics</div>
            <div className="tabs">
                {
                    ['All', 'Active', 'Completed', 'Passed', 'Upcoming', 'Future']
                        .map((ppm) =>
                            <div key={ppm} className={`tab ${(tab.group === 'ppm' && tab.active === ppm) ? 'active' : ''}`}
                                 onClick={() => setTab({group: 'ppm', active: ppm})}>
                                {ppm}
                            </div>
                        )
                }
            </div>

            {
                tab.group === 'ppm' &&
                <div style={{flex: 1}}>
                    <div className={'table_container'}>
                        <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell style={{width: '40px'}}></TableHeaderCell>
                                    <TableHeaderCell style={{width: '120px'}}>Service Date</TableHeaderCell>
                                    <TableHeaderCell style={{width: '170px'}}>PPM Name</TableHeaderCell>
                                    <TableHeaderCell style={{width: '80px'}}>Status</TableHeaderCell>
                                </TableRow>
                            </TableHeader>

                            <TableBody>
                                {
                                    params.ppms
                                        .filter((ppm) => {
                                            if (tab.active === "Active") {
                                                return ppm.serviceStatus === 'Pending'
                                            } else if (tab.active === "Completed") {
                                                return ppm.serviceStatus === 'Completed'
                                            } else if (tab.active === "Passed") {
                                                return ppm.days < 0 && ppm.serviceStatus === 'Pending'
                                            } else if (tab.active === "Upcoming") {
                                                return ppm.days >= 0 && ppm.days <= 10
                                            } else if (tab.active === "Future") {
                                                return ppm.days > 10
                                            }
                                            return true
                                        })
                                        .sort((a, b) => moment(a.serviceDate).isBefore(moment(b.serviceDate)) ? -1 : 1)
                                        .map((ppm) =>
                                            <TableRow key={ppm.ppmId}>
                                                <TableCell style={{width: '40px'}}></TableCell>
                                                <TableCell style={{width: '120px'}}>{moment(ppm.serviceDate).format("ll")}</TableCell>
                                                <TableCell style={{width: '170px'}}>{ppm.ppmName}</TableCell>
                                                <TableCell style={{width: '80px'}} className={`ppm_tab ${getPPmClass(ppm)}`}>
                                                    {ppm.serviceStatus}
                                                </TableCell>
                                            </TableRow>
                                        )
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
            }

            <div className={'statistics_header'}>Work Orders Statistics</div>
            <div className={'tabs'}>
                {
                    ['Pending', 'Rejected', 'Approved', 'Opened', 'In Progress', 'Closed']
                        .map((orderState) =>
                            <div key={orderState}
                                 className={`tab ${(tab.group === 'order' && tab.active === orderState) ? 'active' : ''}`}
                                 onClick={() => setTab({group: 'order', active: orderState})}>
                                {orderState}
                            </div>
                        )
                }
            </div>
            {
                tab.group === 'order' &&
                <div style={{flex: 1}}>
                    <div className={'table_container'}>
                        <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell style={{width: '40px'}}></TableHeaderCell>
                                    <TableHeaderCell style={{width: '120px'}}>Date Created</TableHeaderCell>
                                    <TableHeaderCell style={{width: '150px'}}>Asset Category</TableHeaderCell>
                                    <TableHeaderCell style={{width: '100px'}}>Work Level</TableHeaderCell>
                                </TableRow>
                            </TableHeader>

                            <TableBody>
                                {
                                    params.orders
                                        .filter((order) => order.workStatus === tab.active)
                                        .map((order) =>
                                            <TableRow key={order.workOrderId}>
                                                <TableCell style={{width: '40px'}}></TableCell>
                                                <TableCell style={{width: '120px'}}>{moment(order.timeCreated).format("lll")}</TableCell>
                                                <TableCell style={{width: '150px'}}>
                                                    {order.assetCategory === 'Other' ? order.assetCategoryOther : order.assetCategory}
                                                </TableCell>
                                                <TableCell style={{width: '100px'}}>{order.workLevel}</TableCell>
                                            </TableRow>
                                        )
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
            }
        </>
    )
}
