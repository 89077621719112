import React from "react";
import MyInput from "../../../../libs/components/MyInput";
import FormButtons from "../../../../libs/components/FormButtons";
import {Company} from "../../../../libs/models/Company";
import {BranchData} from "../../../../libs/models/Branch";
import {useToast} from "../../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../../libs/components/providers/ProgressProvider";
import {User} from "../../../../libs/models/User";
import Requests from "../../../../libs/Requests";
import {Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import {MySelect} from "../../../../libs/components/MySelect";
import {UserGroup} from "../../../../libs/models/UserGroup";
import Groups from "./Groups";
import Utilities from "../../../../libs/Utilities";
import ResetPassword from "./ResetPassword";

export default function Users(params: { branches: Array<BranchData>, companies: Company[] }) {
    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const initialUser = {
        branchId     : "", emailAddress: "", firstName: "", gender: 'Female', groupId: "", lastName: "",
        mobileContact: "", userId: "", companyId: "", loginPassword: "", confirmPassword: ""
    }
    const [user, setUser] = React.useState(initialUser)
    const handleUser = (name: string, value: string) => {
        setUser({...user, [name]: value})
    }

    const saveUser = () => {
        const contact = Utilities.formatContact(user.mobileContact)
        if (user.companyId === "") {
            errorToast({message: 'Select user company'})
        } else if (user.branchId === "") {
            errorToast({message: 'Select user branch'})
        } else if (Utilities.invalidName(user.firstName.trim())) {
            errorToast({message: 'Enter a valid first name'})
        } else if (Utilities.invalidName(user.lastName.trim())) {
            errorToast({message: 'Enter a valid surname'})
        } else if (contact.length !== 12) {
            errorToast({message: 'Contact must be 12 digits'})
        } else if (Utilities.invalid_email(user.emailAddress.trim())) {
            errorToast({message: 'Enter a valid email address'})
        } else if (user.userId === "" && user.loginPassword.length < 6) {
            errorToast({message: 'User password must be at least 6 characters'})
        } else if (user.loginPassword !== user.confirmPassword) {
            errorToast({message: 'Passwords do not match'})
        } else {
            showProgress("Saving user data, please wait")
            Requests.saveUser({
                branchId     : user.branchId,
                emailAddress : user.emailAddress.trim(),
                firstName    : user.firstName.trim(),
                gender       : user.gender,
                groupId      : user.groupId,
                lastName     : user.lastName,
                mobileContact: user.mobileContact,
                userId       : user.userId,
                loginPassword: user.loginPassword === '' ? undefined : user.loginPassword
            })
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: 'User data has been saved successfully'})
                        const bUser = {...user, userId: response.data.userId, confirmPassword: '', loginPassword: ''}
                        if (user.userId === '') {
                            setUsers([...users, bUser])
                        } else {
                            setUsers(users.map((aUser) => aUser.userId === user.userId ? bUser : aUser))
                        }
                        setUser(bUser)
                    } else {
                        errorToast({message: response.data.message ?? 'Could not save user, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not save user, please retry'})
                })
        }
    }

    const [search, setSearch] = React.useState({companyId: '', branchId: ''})
    const [users, setUsers] = React.useState<User[]>([])
    const [groups, setGroups] = React.useState<UserGroup[]>([])
    const [showGroups, setShowGroups] = React.useState(false)
    const [showReset, setShowReset] = React.useState(false)

    const loadUsers = () => {
        showProgress("Loading user data, please wait")
        Requests.getUsersAndGroups({companyId: search.companyId, branchId: search.branchId})
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setGroups(response.data.groups)
                    setUsers(response.data.users)
                    successToast({message: 'User data loaded successfully'})
                } else {
                    errorToast({message: response.data.message ?? 'Error loading user data, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
                errorToast({message: 'Error loading user data, please retry'})
            })
    }

    React.useEffect(() => {
        loadUsers()
    }, [])

    return (
        <>
            <Groups
                close={() => setShowGroups(false)} companies={params.companies} groups={groups} show={showGroups}
                update={(group) => {
                    if (groups.filter((aGroup) => aGroup.groupId === group.groupId).length === 1) {
                        setGroups(groups.map((aGroup) => aGroup.groupId === group.groupId ? group : aGroup))
                    } else {
                        setGroups([...groups, group])
                    }
                }}/>

            <ResetPassword close={() => setShowReset(false)} show={showReset} user={user}/>

            <div className={'filter_container'}>
                <div className={'filters'}>
                    <div>
                        <MySelect
                            name={"companyId"} value={search.companyId} placeholder={"Select company name"} className={'mb-0'}
                            onChange={(name, value) => setSearch({...search, [name]: value as string, branchId: ''})}
                            options={[
                                {text: 'Select a company', value: ''},
                                ...params.companies.map((company) => ({text: company.companyName, value: company.companyId}))
                            ]}/>
                    </div>

                    <div>
                        <MySelect
                            name={"branchId"} value={search.branchId} placeholder={"Select branch name"} className={'mb-0'}
                            onChange={(name, value) => setSearch({...search, [name]: value as string})}
                            options={[
                                {text: 'Search all branches', value: ''},
                                ...params.branches
                                    .filter((branch) => branch.companyId === search.companyId)
                                    .map((branch) => ({text: branch.branchName, value: branch.branchId}))
                            ]}/>
                    </div>
                </div>

                <div className={'buttons'}>
                    <Button primary icon labelPosition='left' onClick={() => setShowGroups(true)}>
                        <Icon name='group'/>Groups
                    </Button>

                    <Button icon labelPosition='left' primary onClick={() => setUser(initialUser)}>
                        <Icon name='plus'/>Add New
                    </Button>

                    <Button icon labelPosition='left' primary onClick={loadUsers}>
                        <Icon name='search'/>Search
                    </Button>
                </div>
            </div>

            <div className={'module_content'}>
                <div className={'row m-0 h-100'}>
                    <div className={'col-8 ps-0 pe-1 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '80px'}}></TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>Company Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>Branch Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>Full Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '100px'}}>Contact</TableHeaderCell>
                                        <TableHeaderCell style={{width: '120px'}}>Group</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        users.map((aUser) =>
                                            <TableRow>
                                                <TableCell style={{width: '80px'}} textAlign={'center'}>
                                                    <Button primary size='mini' icon='edit' compact
                                                            onClick={() => setUser({confirmPassword: "", loginPassword: "", ...aUser})}/>

                                                    <Button primary size='mini' icon='unlock' compact
                                                            onClick={() => {
                                                                setShowReset(true)
                                                                setUser({confirmPassword: "", loginPassword: "", ...aUser})
                                                            }}/>
                                                </TableCell>
                                                <TableCell style={{width: '150px'}}>
                                                    {params.companies.filter((company) => company.companyId === aUser.companyId)[0]?.companyName}
                                                </TableCell>
                                                <TableCell style={{width: '150px'}}>
                                                    {params.branches.filter((branch) => branch.branchId === aUser.branchId)[0]?.branchName}
                                                </TableCell>
                                                <TableCell style={{width: '150px'}}>{`${aUser.lastName} ${aUser.firstName}`}</TableCell>
                                                <TableCell style={{width: '100px'}}>{aUser.mobileContact}</TableCell>
                                                <TableCell style={{width: '120px'}}>
                                                    {groups.filter((group) => group.companyId === aUser.companyId)[0]?.groupName}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className={'col-4 ps-1 pe-0 h-100'}>
                        <div className={'form_container'}>
                            <div className={'form_input'}>
                                <MySelect
                                    name={"companyId"} value={user.companyId} placeholder={"Select company name"} label={'Company Name'}
                                    onChange={(name, value) => setUser({...user, [name]: value as string, branchId: '', groupId: ''})}
                                    options={[
                                        {text: 'Select a company', value: ''},
                                        ...params.companies.map((company) => ({text: company.companyName, value: company.companyId}))
                                    ]}/>

                                <MySelect
                                    name={"branchId"} value={user.branchId} placeholder={"Select branch name"} label={'Branch Name'}
                                    onChange={(name, value) => setUser({...user, [name]: value as string, groupId: ''})}
                                    options={[
                                        {text: 'Applicable to all branches', value: ''},
                                        ...params.branches
                                            .filter((branch) => branch.companyId === user.companyId)
                                            .map((branch) => ({text: branch.branchName, value: branch.branchId}))
                                    ]}/>

                                <MyInput name={'firstName'} placeholder={'Enter first name'} value={user.firstName} label={'First Name'} onChange={handleUser}/>

                                <MyInput name={'lastName'} placeholder={'Enter last name'} value={user.lastName} label={'Last Name'} onChange={handleUser}/>

                                <MySelect
                                    name={"groupId"} value={user.groupId} placeholder={"Select user group"} label={'Group Name'}
                                    onChange={(name, value) => setUser({...user, [name]: value as string})}
                                    options={[
                                        {text: 'Select user group', value: ''},
                                        ...groups
                                            .filter((group) => group.companyId === user.companyId)
                                            .map((group) => ({text: group.groupName, value: group.groupId}))
                                    ]}/>

                                <MySelect
                                    name={"gender"} value={user.gender} placeholder={"Select user gender"} label={'User Gender'}
                                    onChange={(name, value) => setUser({...user, [name]: value as string})}
                                    options={[{text: 'Female', value: 'Female'}, {text: 'Male', value: 'Male'}]}/>

                                <MyInput name={'mobileContact'} placeholder={'Enter contact (e.g. +256 777 111 222)'} label={'Mobile Contact'}
                                         value={user.mobileContact} onChange={handleUser}/>

                                <MyInput name={'emailAddress'} placeholder={'Enter user email address'}
                                         value={user.emailAddress} label={'Email Address'} onChange={handleUser}/>

                                {
                                    user.userId === "" &&
                                    <>
                                        <MyInput name={'loginPassword'} placeholder={'Enter login password'} type={'password'}
                                                 value={user.loginPassword} label={'Login Password'} onChange={handleUser}/>

                                        <MyInput name={'confirmPassword'} placeholder={'Confirm login password'} className={'mb-0'} type={'password'}
                                                 value={user.confirmPassword} label={'Confirm Password'} onChange={handleUser}/>
                                    </>
                                }
                            </div>

                            <FormButtons left={{click: () => setUser(initialUser)}} right={{click: saveUser}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
