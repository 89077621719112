import React from 'react';
import NavHeader from "../libs/nav/NavHeader";
import {Pages} from "../libs/models/Types";

import Companies from "./modules/admin/companies/Companies";
import Licenses from "./modules/admin/licenses/Licenses";
import Users from "./modules/admin/users/Users";
import {useToast} from "../libs/components/providers/ToastProvider";
import {useAppDispatch, useAppSelector} from "../libs/store/hooks";
import {getBearer, getStaff, updateBearer, updateCounter} from "../libs/store/slices/userSlice";
import Utilities from "../libs/Utilities";
import axios from "axios";
import Login from "./modules/Login";
import Assets from "./modules/assets/Assets";
import Requests from "../libs/Requests";
import {Company} from "../libs/models/Company";
import {BranchData} from "../libs/models/Branch";
import {useProgress} from "../libs/components/providers/ProgressProvider";
import {WorkOrderSite} from "../libs/models/WorkOrderSite";
import CreateWorkOrder from "./modules/workOrder/CreateWorkOrder";
import ListWorkOrder from "./modules/workOrder/ListWorkOrder";
import CreatePPM from "./modules/ppm/CreatePPM";
import ViewPPMs from "./modules/ppm/ViewPPMs";
import {UserData} from "../libs/models/User";
import Reports from "./modules/reports/Reports";
import Home from "./modules/home/Home";

export default function App() {
    const {showProgress, hideProgress} = useProgress()
    const {errorToast} = useToast();

    const dispatch = useAppDispatch()
    const staff = useAppSelector(getStaff)
    const bearerToken = useAppSelector(getBearer)

    const [page, setPage] = React.useState<Pages>('Home')
    const [users, setUsers] = React.useState<Array<UserData>>([])
    const [companies, setCompanies] = React.useState(Array<Company>())
    const [branches, setBranches] = React.useState(Array<BranchData>())
    const [workSites, setWorkSites] = React.useState(Array<WorkOrderSite>())
    const [status, setStatus] = React.useState<{ locked: string, upgrade: boolean }>({locked: '', upgrade: false})

    const set_axios_data = () => {
        axios.defaults.baseURL = Utilities.BASE_URL;

        axios.defaults.headers.common.Authorization = `Bearer ${bearerToken}`
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        axios.defaults.timeout = 1000 * 60

        axios.interceptors.response.use(
            function (response) {
                console.log(response)
                console.log(response.data)

                if (response.data.hasOwnProperty("code")) {
                    if (response.data['code'] === 426) {
                        setStatus({locked: '', upgrade: true})
                    } else if (response.data['code'] === 401) {
                        dispatch(updateBearer(""))
                    } else if (response.data.code === 423) {
                        setStatus({locked: response.data.time, upgrade: false})
                    } else if (response.data.code === 503) {
                        errorToast({message: `This service is not yet available at the moment`})
                    }
                }

                return response
            },
            function (error) {
                console.log(error)
                return Promise.reject(error);
            });
    }
    set_axios_data()

    window.onclick = () => {
        dispatch(updateCounter(0))
    }

    const initialiseData = () => {
        showProgress("Initialising data, please wait")
        Requests.initialiseData()
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setCompanies(response.data.companies)
                    setBranches(response.data.branches)
                    setWorkSites(response.data.sites)
                    setUsers(response.data.users)
                } else {
                    errorToast({message: 'Error loading initial data, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
                errorToast({message: 'Error loading initial data, please retry'})
            })
    }

    React.useEffect(() => {
        axios.defaults.headers['common']['Authorization'] = `Bearer ${bearerToken}`
    }, [bearerToken])

    React.useEffect(() => {
        if (bearerToken !== "") {
            initialiseData()
        }
    }, [staff, bearerToken])

    return (
        <>
            {
                (bearerToken === "") ?
                    <Login/> :
                    <div className={'main_body'}>
                        <NavHeader page={page} setPage={setPage}/>

                        <div className={'content_body'}>
                            <div className={'content_container'}>
                                {page === 'Home' && <Home/>}

                                {page === 'Admin Companies' && <Companies/>}
                                {page === 'Admin Licenses' && <Licenses/>}
                                {page === 'Admin Users ' && <Users branches={branches} companies={companies}/>}

                                {page === 'Assets' && <Assets branches={branches}/>}

                                {page === 'Create WorkOrders' && <CreateWorkOrder sites={workSites}/>}
                                {
                                    ["Approve WorkOrders", 'WorkOrders-Approved', 'WorkOrders-Open', 'WorkOrders-InProgress', 'WorkOrders-Closed', 'WorkOrders-All'].includes(page) &&
                                    <ListWorkOrder
                                        branches={branches} page={page} sites={workSites}
                                        updateSite={(site) => {
                                            if (workSites.filter((aSite) => aSite.siteId === site.siteId).length === 1) {
                                                setWorkSites(workSites.map((aSite) => aSite.siteId === site.siteId ? site : aSite))
                                            } else {
                                                setWorkSites([...workSites, site])
                                            }
                                        }}/>
                                }

                                {page === 'Create PPM' && <CreatePPM/>}
                                {page === 'View PPMs' && <ViewPPMs branches={branches} users={users}/>}


                                {
                                    [
                                        "Reports-Work-Orders-Date", 'Reports-Work-Orders-Open', 'Reports-Work-Orders-Upcoming', 'Reports-Work-Orders-Pending',
                                        'Reports-Work-Orders-Overdue', 'Reports-Work-Orders-Technician', 'Reports-Work-Orders-Site',
                                        'Reports-PPM-Closed', 'Reports-PPM-Overdue', 'Reports-PPM-Technician', 'Reports-PPM-Site', 'Reports-PPM-All'
                                    ].includes(page) && <Reports page={page} users={users} branches={branches} sites={workSites}/>
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
