import axios from "axios";

export default class Requests {
    static initialiseData() {
        return axios({method: 'get', url: 'oms/init'});
    }

    static getHomeData() {
        return axios({method: 'get', url: 'oms/home'});
    }

    static saveCompany(data: { companyId: string, companyName: string }) {
        return axios({method: 'post', url: 'oms/admin/company', data: data});
    }

    static saveBranch(data: { branchId: string, companyId: string, branchName: string, branchContact: string, branchEmail: string, branchLocation: string }) {
        return axios({method: 'post', url: 'oms/admin/branch', data: data});
    }

    static saveLicense(data: { licenseId: string, companyId: string, licenseKey: string, startDate: string, expiryDate: string, branchId: string }) {
        return axios({method: 'post', url: 'oms/admin/license', data: data});
    }

    static getLicenses(params: {}) {
        return axios({method: 'get', url: 'oms/admin/licenses', params: params});
    }

    static getCompanies(params: {}) {
        return axios({method: 'get', url: 'oms/admin/companies', params: params});
    }

    /*USER MANAGEMENT*/
    static saveGroup(data: { groupId: string, companyId: string, groupName: string, permissions: string }) {
        return axios({method: 'post', url: 'oms/users/group', data: data});
    }

    static saveUser(data: {
        userId: string, branchId: string, groupId: string, firstName: string, lastName: string,
        emailAddress: string, mobileContact: string, gender: string, loginPassword?: string
    }) {
        return axios({method: 'post', url: 'oms/users/user', data: data});
    }

    static getUsersAndGroups(params: { companyId: string, branchId: string }) {
        return axios({method: 'get', url: 'oms/users/groupsUsers', params: params});
    }

    static resetPassword(data: { loginPassword: string, userId: string }) {
        return axios({method: 'post', url: 'oms/users/auth/resetPassword', data: data});
    }

    static changePassword(data: { loginPassword: string, newPassword: string }) {
        return axios({method: 'post', url: 'oms/users/auth/changePassword', data: data});
    }

    static loginUser(data: { credential: string, loginPassword: string }) {
        return axios({method: 'post', url: 'oms/users/auth/loginUser', data: data});
    }

    /*ASSETS MANAGEMENT*/
    static saveAsset(data: {
        assetId: string, branchId: string, assetName: string, assetCode: string,
        location: string, acquisitionDate: string, acquisitionCost: number
    }) {
        return axios({method: 'post', url: 'oms/assets/asset', data: data});
    }

    static getAssets(params: { branchId: string, name: string, exportType?: 'pdf' | 'csv' }) {
        return axios({method: 'get', url: 'oms/assets/assets', params: params});
    }

    /*PPM MANAGEMENT*/
    static savePPM(data: {
        pmId: string, branchId: string, ppmName: string, ppmAddress: string, serviceFrequency: string,
        startDate: string, endDate: string, description: string
    }) {
        return axios({method: 'post', url: 'oms/ppm/ppm', data: data});
    }

    static savePPMService(data: { ppmId: string, serviceDate: string, serviceNotes: string, serviceStatus: string, technicianId: string, ppmServiceId: string }) {
        return axios({method: 'post', url: 'oms/ppm/service', data: data});
    }

    static getPPMs(params: { branchId: string }) {
        return axios({method: 'get', url: 'oms/ppm/list', params: params});
    }

    static getPPMsReport(params: {
        exportType?: 'pdf' | 'csv', minDate: string, maxDate: string, technicianId?: string, siteId?: string, branchId: string,
        reportType: 'Closed' | 'Overdue' | 'Technician' | 'Site' | 'All'
    }) {
        return axios({method: 'get', url: 'oms/ppm/report', params: params});
    }

    /*WORK ORDERS*/
    static saveWorkOrderSite(data: { companyId: string, siteId: string, siteName: string }) {
        return axios({method: 'post', url: 'oms/workOrder/site', data: data});
    }

    static saveWorkOrder(data: {
        workOrderId: string, branchId: string, siteId: string, workLevel: string, assetCategoryOther: string,
        assetCategory: string, description: string, orderNo: string, workImage?: File
    }) {
        const formData = new FormData()
        formData.append("workOrderId", data.workOrderId)
        formData.append("branchId", data.branchId)
        formData.append("siteId", data.siteId)
        formData.append("workLevel", data.workLevel)
        formData.append("assetCategoryOther", data.assetCategoryOther)
        formData.append("assetCategory", data.assetCategory)
        formData.append("description", data.description)
        formData.append("orderNo", data.orderNo)

        if (data.workImage !== undefined) {
            formData.append('workImage', data.workImage)
        }

        return axios({method: 'post', url: 'oms/workOrder/order', data: formData, headers: {'Content-Type': 'multipart/form-data'}});
    }

    static saveWorkOrderStatus(data: { workOrderId: string, workStatus: string, description: string }) {
        return axios({method: 'post', url: 'oms/workOrder/status', data: data});
    }

    static saveWorkOrderUser(data: { workOrderId: string, userId: string, userType: string, userComments: string }) {
        return axios({method: 'post', url: 'oms/workOrder/user', data: data});
    }

    static getWorkOrders(params: { workStatus: string, branchId: string }) {
        return axios({method: 'get', url: 'oms/workOrder/orders', params: params});
    }

    static getWorkOrdersReport(params: {
        exportType?: 'pdf' | 'csv', minDate: string, maxDate: string, technicianId?: string, siteId?: string, branchId: string,
        reportType: 'Date' | 'Open' | 'Upcoming' | 'Pending' | 'Overdue' | 'Technician' | 'Site'
    }) {
        return axios({method: 'get', url: 'oms/workOrder/report', params: params});
    }
}
