import {Branch} from "../../../../libs/models/Branch";
import {Company} from "../../../../libs/models/Company";
import {Button, Modal, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import React from "react";
import FormButtons from "../../../../libs/components/FormButtons";
import {useToast} from "../../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../../libs/components/providers/ProgressProvider";
import MyInput from "../../../../libs/components/MyInput";
import FormTextArea from "../../../../libs/components/FormTextArea";
import Requests from "../../../../libs/Requests";
import Utilities from "../../../../libs/Utilities";

export default function Branches(params: { show: boolean, close: () => void, branches: Branch[], company: Company, update: (branch: Branch) => void }) {
    const initialBranch: Branch = {branchContact: "", branchEmail: "", branchId: "", branchLocation: "", branchName: "", companyId: ""}
    const [branch, setBranch] = React.useState(initialBranch)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const handleChange = (name: string, value: string) => {
        setBranch({...branch, [name]: value})
    }

    const saveBranch = () => {
        const contact = Utilities.formatContact(branch.branchContact)
        if (branch.branchName.trim().length < 3) {
            errorToast({message: 'Enter a valid branch name'})
        } else if (contact.length !== 12) {
            errorToast({message: 'Contact must be 12 digits'})
        } else if (Utilities.invalid_email(branch.branchEmail)) {
            errorToast({message: 'Enter a valid email address'})
        } else if (branch.branchLocation.length < 5 || branch.branchLocation.length > 500) {
            errorToast({message: 'Location must be between 5 and 500 characters'})
        } else {
            showProgress("Saving branch data, please wait")
            Requests.saveBranch({
                branchContact : contact,
                branchEmail   : branch.branchEmail.trim(),
                branchId      : branch.branchId,
                branchLocation: branch.branchLocation.trim(),
                branchName    : branch.branchName.trim(),
                companyId     : params.company.companyId
            })
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        setBranch({...branch, branchContact: contact, branchId: response.data.branchId})
                        successToast({message: 'Branch has been saved successfully'})
                        params.update({...branch, branchContact: contact, branchId: response.data.branchId})
                    } else {
                        errorToast({message: response.data.message ?? 'Could not save branch, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not save branch, please retry'})
                })
        }

    }

    React.useEffect(() => {
        setBranch({...initialBranch, companyId: params.company.companyId})
    }, [params.show, params.company])

    return (
        <Modal open={params.show} basic size='large' centered={false}>
            <div className="modal_div full">
                <div className="form_header">Branches for {params.company.companyName}</div>

                <div className={'row mx-0'} style={{flex: 1}}>
                    <div className={'col-8 ps-0 pe-1 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '60px'}}></TableHeaderCell>
                                        <TableHeaderCell>Branch Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '120px'}}>Contact</TableHeaderCell>
                                        <TableHeaderCell style={{width: '300px'}}>Location</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        params.branches.map((aBranch) =>
                                            <TableRow key={aBranch.branchId}>
                                                <TableCell style={{width: '60px'}} textAlign={'center'}>
                                                    <Button primary size='mini' icon='edit' compact onClick={() => setBranch(aBranch)}/>
                                                </TableCell>
                                                <TableCell>{aBranch.branchName}</TableCell>
                                                <TableCell style={{width: '120px'}}>{aBranch.branchContact}</TableCell>
                                                <TableCell style={{width: '300px'}}>{aBranch.branchLocation}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className={'col-4 ps-0 pe-1 h-100'}>
                        <div className="form_container">
                            <div className={'form_input'}>
                                <MyInput name={'branchName'} placeholder={'Enter branch name'} label={'Branch Name'}
                                         value={branch.branchName} onChange={handleChange}/>

                                <MyInput name={'branchContact'} placeholder={'Enter contact (e.g. +256 777 111 222)'} label={'Mobile Contact'}
                                         value={branch.branchContact} onChange={handleChange}/>

                                <MyInput name={'branchEmail'} placeholder={'Enter branch email address'}
                                         value={branch.branchEmail} label={'Email Address'} onChange={handleChange}/>

                                <FormTextArea name={'branchLocation'} placeholder={"Enter location of branch"} label={'Location of Branch'}
                                              value={branch.branchLocation} rows={5} className={'mb-0'} onChange={handleChange}/>
                            </div>

                            <FormButtons
                                left={{
                                    text : branch.branchId === '' ? 'Close Window' : 'Clear Data',
                                    click: () => branch.branchId === '' ? params.close() : setBranch({...initialBranch, companyId: params.company.companyId})
                                }}
                                right={{click: saveBranch}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
