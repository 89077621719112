import {BranchData} from "../../../libs/models/Branch";
import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";
import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";
import {PPM, PPMData} from "../../../libs/models/PPM";
import React from "react";
import EditPPM from "./create/EditPPM";
import Requests from "../../../libs/Requests";
import {MySelect} from "../../../libs/components/MySelect";
import {Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import moment from "moment";
import PPMServices from "./services/PPMServices";
import {UserData} from "../../../libs/models/User";

export default function ViewPPMs(params: { branches: Array<BranchData>, users: UserData[] }) {
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const initialPPm: PPM = {ppmId: "", branchId: staff.branchId, ppmName: "", ppmAddress: "", serviceFrequency: 'Monthly', startDate: "", endDate: "", description: ""}
    const [editPPM, setEditPPM] = React.useState({show: false, ppm: initialPPm});

    const [search, setSearch] = React.useState({branchId: staff.branchId});
    const [ppmData, setPpmData] = React.useState(Array<PPMData>())
    const [showPPMService, setShowPPMService] = React.useState<{ ppmData?: PPMData }>({})

    const getPPMs = () => {
        showProgress("Loading ppm data, please wait")
        Requests.getPPMs({branchId: search.branchId})
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setPpmData(response.data.ppm)
                    successToast({message: 'PPM data loaded successfully'})
                } else {
                    errorToast({message: response.data.message ?? 'Error loading PPM data, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
                errorToast({message: 'Error loading PPM data, please retry'})
            })
    }

    React.useEffect(() => {
        getPPMs()
    }, [])

    return (
        <>
            <EditPPM
                ppm={editPPM.ppm} show={editPPM.show}
                close={() => setEditPPM({show: false, ppm: initialPPm})}
                update={(ppm: PPM) => {
                    setPpmData(ppmData.map((aPPM) => aPPM.ppmId === ppm.ppmId ? {...aPPM, ...ppm} : {...aPPM}))
                    setEditPPM({...editPPM, ppm: ppm})
                }}/>

            <PPMServices
                close={() => setShowPPMService({})} ppmData={showPPMService.ppmData} users={params.users}
                update={(aData) => setPpmData(ppmData.map((aPPM) => aPPM.ppmId === aData.ppmId ? aData : aPPM))}/>

            <div className={'filter_container'}>
                <div className={'filters'}>
                    <div>
                        <MySelect
                            name={"branchId"} value={search.branchId} placeholder={"Select branch name"} className={'mb-0'}
                            onChange={(name, value) => setSearch({...search, [name]: value as string})}
                            options={[
                                {text: 'Search all branches', value: ''},
                                ...params.branches
                                    .filter((branch) => branch.companyId === staff.companyId)
                                    .map((branch) => ({text: branch.branchName, value: branch.branchId}))
                            ]}/>
                    </div>
                </div>

                <div className={'buttons'}>
                    <Button icon labelPosition='left' primary onClick={getPPMs}>
                        <Icon name='search'/>Search
                    </Button>
                </div>
            </div>

            <div className={'module_content'}>
                <div className={'row m-0 h-100'}>
                    <div className={'col-12 px-0 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '70px'}}></TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>Branch Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '160px'}}>PPM Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '190px'}}>PPM Address</TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>Next Service</TableHeaderCell>
                                        <TableHeaderCell style={{width: '50px'}} textAlign={'center'}>Days</TableHeaderCell>
                                        <TableHeaderCell style={{width: '70px'}}>Frequency</TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>End Date</TableHeaderCell>
                                        <TableHeaderCell style={{width: '70px'}}>Status</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        ppmData.map((ppm) =>
                                            <TableRow key={ppm.ppmId}>
                                                <TableCell style={{width: '70px'}} textAlign={'center'}>
                                                    <Button primary size='mini' icon='edit' compact
                                                            onClick={() => setEditPPM({
                                                                ppm : {
                                                                    ppmId           : ppm.ppmId,
                                                                    branchId        : ppm.branchId,
                                                                    ppmName         : ppm.ppmName,
                                                                    ppmAddress      : ppm.ppmAddress,
                                                                    serviceFrequency: ppm.serviceFrequency,
                                                                    startDate       : ppm.startDate,
                                                                    endDate         : ppm.endDate,
                                                                    description     : ppm.description
                                                                },
                                                                show: true
                                                            })}/>
                                                    <Button primary size='mini' icon='cogs' compact onClick={() => setShowPPMService({ppmData: ppm})}/>
                                                </TableCell>
                                                <TableCell style={{width: '150px'}}>{ppm.branchName}</TableCell>
                                                <TableCell style={{width: '160px'}}>{ppm.ppmName}</TableCell>
                                                <TableCell style={{width: '190px'}}>{ppm.ppmAddress}</TableCell>
                                                <TableCell style={{width: '80px'}}>{moment(ppm.nextDate).format("ll")}</TableCell>
                                                <TableCell style={{width: '50px'}} textAlign={'center'}>{ppm.daysToService}</TableCell>
                                                <TableCell style={{width: '70px'}}>{ppm.serviceFrequency}</TableCell>
                                                <TableCell style={{width: '80px'}}>{moment(ppm.endDate).format("ll")}</TableCell>
                                                <TableCell style={{width: '70px'}}>{ppm.ppmStatus}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
