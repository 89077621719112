import {Company} from "../../../../libs/models/Company";
import {UserGroup} from "../../../../libs/models/UserGroup";
import {Button, Modal, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import MyInput from "../../../../libs/components/MyInput";
import FormButtons from "../../../../libs/components/FormButtons";
import React from "react";
import {useToast} from "../../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../../libs/components/providers/ProgressProvider";
import {GroupRoles, RoleGroups} from "../../../../libs/models/Types";
import MyCheckBox from "../../../../libs/components/MyCheckBox";
import {MySelect} from "../../../../libs/components/MySelect";
import Requests from "../../../../libs/Requests";

export default function Groups(params: { show: boolean, close: () => void, groups: UserGroup[], companies: Company[], update: (group: UserGroup) => void }) {
    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const initialGroup: UserGroup = {companyId: "", groupId: "", groupName: "", permissions: []}
    const [group, setGroup] = React.useState(initialGroup)

    const handleChange = (name: string, value: string) => {
        setGroup({...group, [name]: value})
    }

    const saveGroup = () => {
        if (group.companyId === "") {
            errorToast({message: 'Select a company'})
        } else if (group.groupName.trim().length < 3) {
            errorToast({message: 'Enter a valid group name'})
        } else if (group.permissions.length === 0) {
            errorToast({message: 'No permissions have been selected'})
        } else {
            showProgress("Saving group, please wait")
            Requests.saveGroup({companyId: group.companyId, groupId: group.groupId, groupName: group.groupName.trim(), permissions: JSON.stringify(group.permissions)})
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: 'Group has been saved successfully'})
                        const aGroup = {...group, groupId: response.data.groupId}
                        setGroup(aGroup)
                        params.update(aGroup)
                    } else {
                        errorToast({message: response.data.message ?? 'Could not save group, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not save group, please retry'})
                })
        }
    }

    React.useEffect(() => {
        setGroup(initialGroup)
    }, [params.show])

    return (
        <Modal open={params.show} basic size='large' centered={false}>
            <div className="modal_div full">
                <div className="form_header">User Groups</div>

                <div className={'row mx-0'} style={{flex: 1}}>
                    <div className={'col-7 ps-0 pe-1 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '40px'}}></TableHeaderCell>
                                        <TableHeaderCell style={{width: '200px'}}>Company Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '200px'}}>Group Name</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        params.groups.map((aGroup) =>
                                            <TableRow key={aGroup.groupId}>
                                                <TableCell style={{width: '40px'}} textAlign={'center'}>
                                                    <Button primary size='mini' icon='edit' compact onClick={() => setGroup(aGroup)}/>
                                                </TableCell>
                                                <TableCell style={{width: '200px'}}>
                                                    {params.companies.filter((company) => company.companyId === aGroup.companyId)[0]?.companyName}
                                                </TableCell>
                                                <TableCell style={{width: '200px'}}>{aGroup.groupName}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className={'col-5 ps-0 pe-1 h-100'}>
                        <div className="form_container">
                            <div className={'form_input'}>
                                <MySelect
                                    name={"companyId"} value={group.companyId} placeholder={"Select company name"} label={'Company Name'}
                                    onChange={(name, value) => setGroup({...group, [name]: value as string})}
                                    options={[
                                        {text: 'Select a company', value: ''},
                                        ...params.companies.map((company) => ({text: company.companyName, value: company.companyId}))
                                    ]}/>

                                <MyInput name={'groupName'} placeholder={'Enter group name'} label={'Group Name'}
                                         value={group.groupName} onChange={handleChange}/>

                                {
                                    RoleGroups.map((aGroup) =>
                                        <>
                                            <label className={'form_label mt-2'}>{aGroup.name}</label>
                                            {
                                                aGroup.permissions.map((aPermission) =>
                                                    <div key={aPermission} className={'mb-2'}>
                                                        <MyCheckBox
                                                            checked={group.permissions.includes(aPermission)} label={GroupRoles[aPermission]}
                                                            onChange={(checked) => {
                                                                if (checked) {
                                                                    setGroup({...group, permissions: [...group.permissions, aPermission]})
                                                                } else {
                                                                    setGroup({...group, permissions: group.permissions.filter((aPerm) => aPerm !== aPermission)})
                                                                }
                                                            }}/>
                                                    </div>
                                                )
                                            }

                                        </>
                                    )
                                }
                            </div>

                            <FormButtons
                                left={{
                                    text : group.groupId === '' ? 'Close Window' : 'Clear Data',
                                    click: () => group.groupId === '' ? params.close() : setGroup(initialGroup)
                                }}
                                right={{click: saveGroup}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
