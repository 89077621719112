import React from "react";
import HomeCalendar from "./HomeCalendar";
import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";
import Requests from "../../../libs/Requests";
import {assetCategory, workLevel, workStatus} from "../../../libs/models/WorkOrder";
import HomeStatistics from "./HomeStatistics";
import moment from "moment";

export interface HomeOrder {
    assetCategory: assetCategory
    assetCategoryOther: string
    timeCreated: string
    workLevel: workLevel
    workOrderId: string
    workStatus: workStatus
}

export interface HomePPM {
    days: number
    ppmId: string
    ppmName: string
    ppmServiceId: string
    serviceDate: string
    endDate: string
    startDate: string
    serviceStatus: 'Pending' | 'Completed'
}

export default function Home() {
    const {errorToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [ppms, setPPMs] = React.useState<Array<HomePPM>>([])
    const [orders, setOrders] = React.useState<Array<HomeOrder>>([])

    const getHomeData = () => {
        showProgress("Loading data, please wait")
        Requests.getHomeData()
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setPPMs((response.data.ppms as any[]).map((ppm) => ({...ppm, days: moment(ppm.serviceDate).diff(moment(), 'days')})))
                    setOrders(response.data.orders)
                } else {
                    errorToast({message: "Could not load data, please refresh page"})
                }
            })
            .catch(() => {
                hideProgress()
                errorToast({message: "Could not load data, please refresh page"})
            })
    }

    React.useEffect(() => {
        getHomeData()
    }, [])

    React.useEffect(() => {
        console.log(ppms)
    }, [ppms])

    return (
        <div className={'module_content'} style={{backgroundColor: '#E1E0E0'}}>
            <div className={'row m-0 h-100'}>
                <div className={'col-6 ps-0 pe-1 h-100'}>
                    <div style={{backgroundColor: '#FFFFFF', height: '100%', padding: '1rem'}}>
                        <HomeCalendar orders={orders} ppms={ppms}/>
                    </div>
                </div>

                <div className={'col-6 ps-1 pe-0 h-100'}>
                    <div style={{backgroundColor: '#FFFFFF', height: '100%', padding: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <HomeStatistics orders={orders} ppms={ppms}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
