export function MyDateInput(params: {
    label?: string, minDate?: string, maxDate?: string, value: string, name: string, placeholder: string, className?: string,
    change?: (name: string, value: string) => void, disabled?: boolean, type?: 'date' | 'time'
}) {
    return (
        <>
            {params.label && <label className={'form_label'}>{params.label}</label>}

            <input className={`date_input ${params.className !== undefined ? params.className : 'mb-3'}`}
                   name={params.name} placeholder={params.placeholder} value={params.value}
                   type={params.type === undefined ? 'date' : params.type} max={params.maxDate}
                   min={params.minDate} title={params.placeholder} disabled={params.disabled !== undefined ? params.disabled : false}
                   onChange={(event) => {
                       if (params.change) {
                           params.change(event.target.name, event.target.value)
                       }
                   }}/>
        </>
    )
}
