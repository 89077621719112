import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";
import React from "react";
import {Asset} from "../../../libs/models/Asset";
import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";
import MyInput from "../../../libs/components/MyInput";
import {Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import moment from "moment";
import SaveAsset from "./SaveAsset";
import {BranchData} from "../../../libs/models/Branch";
import Requests from "../../../libs/Requests";
import Utilities from "../../../libs/Utilities";

export interface AssetData {
    assetId: string
    branchId: string
    assetName: string
    assetCode: string
    location: string
    acquisitionDate: string
    acquisitionCost: number
    branchName: string
}

export default function Assets(params: { branches: Array<BranchData> }) {
    const staff = useAppSelector(getStaff)
    const {errorToast, successToast, infoToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [search, setSearch] = React.useState({name: '', branchId: staff.branchId})

    const initialAsset: Asset = {acquisitionCost: "", acquisitionDate: "", assetCode: "", assetId: "", assetName: "", branchId: staff.branchId, location: ""}
    const [assets, setAssets] = React.useState<AssetData[]>([])
    const [asset, setAsset] = React.useState<Asset>(initialAsset)

    const searchAssets = ({exportType}: { exportType?: 'pdf' | 'csv' }) => {
        showProgress("Searching assets, please wait")
        Requests.getAssets({branchId: search.branchId, name: search.name.trim(), exportType: exportType})
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setAssets(response.data.assets)
                    successToast({message: 'Assets loaded successfully'})
                    if (response.data.hasOwnProperty("path")) {
                        Utilities.downloadFile(response.data.path)
                    }
                } else {
                    errorToast({message: response.data.message ?? 'Error loading assets, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
                errorToast({message: 'Error loading assets, please retry'})
            })
    }

    React.useEffect(() => {
        if (assets.length === 0) {
            infoToast({message: "No assets are present"})
        }
    }, [assets])

    React.useEffect(() => {
        searchAssets({})
    }, [])

    return (
        <>
            <div className={'filter_container'}>
                <div className={'filters'}>
                    <div>
                        <MyInput name={'name'} placeholder={'Search by asset name or code'} className={'mb-0'}
                                 value={search.name} onChange={(name, value) => setSearch({...search, [name]: value})}/>
                    </div>
                </div>

                <div className={'buttons'}>
                    <Button icon labelPosition='left' primary onClick={() => setAsset(initialAsset)} size={'mini'}>
                        <Icon name='plus'/>Add New
                    </Button>

                    <Button primary icon labelPosition='left' onClick={() => searchAssets({exportType: 'pdf'})} disabled={assets.length === 0} size={'mini'}>
                        <Icon name='file pdf'/>Print PDF
                    </Button>

                    <Button icon labelPosition='left' primary onClick={() => searchAssets({exportType: 'csv'})} disabled={assets.length === 0} size={'mini'}>
                        <Icon name='file excel'/>Export CSV
                    </Button>

                    <Button icon labelPosition='left' primary onClick={() => searchAssets({})} size={'mini'}>
                        <Icon name='search'/>Search
                    </Button>
                </div>
            </div>

            <div className={'module_content'}>
                <div className={'row m-0 h-100'}>
                    <div className={'col-8 ps-0 pe-1 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '40px'}}></TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>Branch Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>Asset Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '120px'}}>Asset Code</TableHeaderCell>
                                        <TableHeaderCell style={{width: '120px'}}>Date Acquired</TableHeaderCell>
                                        <TableHeaderCell style={{width: '120px'}}>Acquisition Cost</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        assets.map((aAsset) =>
                                            <TableRow>
                                                <TableCell style={{width: '40px'}} textAlign={'center'}>
                                                    <Button primary size='mini' icon='edit' compact
                                                            onClick={() =>
                                                                setAsset({
                                                                    acquisitionCost: aAsset.acquisitionCost.toString(),
                                                                    acquisitionDate: aAsset.acquisitionDate,
                                                                    assetCode      : aAsset.assetCode,
                                                                    assetId        : aAsset.assetId,
                                                                    assetName      : aAsset.assetName,
                                                                    branchId       : aAsset.branchId,
                                                                    location       : aAsset.location
                                                                })
                                                            }/>
                                                </TableCell>
                                                <TableCell style={{width: '150px'}}>{aAsset.branchName}</TableCell>
                                                <TableCell style={{width: '150px'}}>{aAsset.assetName}</TableCell>
                                                <TableCell style={{width: '120px'}}>{aAsset.assetCode}</TableCell>
                                                <TableCell style={{width: '120px'}}>{moment(aAsset.acquisitionDate).format("ll")}</TableCell>
                                                <TableCell style={{width: '120px'}}>{aAsset.acquisitionCost.toLocaleString()}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className={'col-4 ps-1 pe-0 h-100'}>
                        <div className={'form_container'}>
                            <SaveAsset
                                asset={asset} initialAsset={initialAsset} branches={params.branches.filter((branch) => branch.companyId === staff.companyId)}
                                update={(aAsset) => {
                                    if (assets.filter((bAsset) => bAsset.assetId === aAsset.assetId).length === 1) {
                                        setAssets(assets.map((bAsset) => bAsset.assetId === aAsset.assetId ? aAsset : bAsset))
                                    } else {
                                        setAssets([...assets, aAsset])
                                    }
                                }}/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
