import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {persistedStore, store} from "./libs/store/store";
import {ProgressProvider} from "./libs/components/providers/ProgressProvider";
import {ToastProvider} from "./libs/components/providers/ToastProvider";
import {PersistGate} from "redux-persist/integration/react";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";

import 'semantic-ui-css/semantic.min.css'
import './libs/styles/styles.scss';

const render = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<App/>}>
                <Route index element={<App/>}/>
                <Route path="*" element={<App/>}/>
            </Route>
        )
    )

    ReactDOM
        .createRoot(document.getElementById('root') as HTMLElement)
        .render(
            <Provider store={store}>
                <ProgressProvider>
                    <ToastProvider>
                        <PersistGate loading={null} persistor={persistedStore}>
                            <RouterProvider router={router}/>
                        </PersistGate>
                    </ToastProvider>
                </ProgressProvider>
            </Provider>
        )
}

render()

// If you want to start measuring performance in your app, pass a function
// to log results (for example, reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
