import {Form, TextArea} from "semantic-ui-react";

export default function FormTextArea(params: {
    name: string, label?: string, placeholder: string, value: string, rows: number, className?: string,
    onChange: (name: string, value: string) => void
}) {
    return (
        <Form className={params.className ?? 'mb-3'}>
            {params.label && <label className={'form_label'}>{params.label}</label>}
            <TextArea
                placeholder={params.placeholder} rows={params.rows} value={params.value}
                onChange={(_event, data) => params.onChange(params.name, data.value as string)}/>
        </Form>
    )
}
