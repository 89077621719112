import {Pages} from "../models/Types";

export default function NavHeader(params: { page: Pages, setPage: (page: Pages) => void }) {
    return (
        <>
            <nav className="nav-area">
                <ul className="menu">
                    <li className={`${params.page === 'Home' ? 'active' : ''}`}>
                        <span className={'link'} onClick={() => params.setPage('Home')}>Home</span>
                    </li>

                    <li className={`${params.page === 'Assets' ? 'active' : ''}`}>
                        <span className={'link'} onClick={() => params.setPage('Assets')}>Assets</span>
                    </li>

                    <li className={`${params.page.includes("WorkOrder") ? 'active' : ''}`}>
                        <span className={'link'}>Work Orders</span>
                        <ul>
                            <li>
                                <span className={'link'} onClick={() => params.setPage('Create WorkOrders')}>Create Work Orders</span>
                            </li>
                            <li><span className={'link'} onClick={() => params.setPage('Approve WorkOrders')}>Approve Work Orders</span></li>
                            <li>
                                <span className={'link'}>List of Work Orders</span>
                                <ul>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-Approved')}>Approved, Not Started</span></li>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-Open')}>Open Orders</span></li>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-InProgress')}>Work In Progress</span></li>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-Closed')}>Closed Orders</span></li>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-All')}>All Orders</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li className={`${['Create PPM', 'View PPMs'].includes(params.page) ? 'active' : ''}`}>
                        <span className={'link'}>PPMs</span>
                        <ul>
                            <li>
                                <span className={'link'} onClick={() => params.setPage('Create PPM')}>Create PPMs</span>
                            </li>
                            <li><span className={'link'} onClick={() => params.setPage('View PPMs')}>View PPMs</span></li>
                        </ul>
                    </li>


                    <li className={`${params.page.includes('Reports-') ? 'active' : ''}`}>
                        <span className={'link'}>Reports</span>
                        <ul>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Date')}>Work orders per day, week, month, year</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Open')}>Open Work Orders</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Upcoming')}>Upcoming Work Orders</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Pending')}>Pending Work Orders</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Overdue')}>Overdue / non completed work orders</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Technician')}>Work orders per technician</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Site')}>Work orders per location or site</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-Closed')}>PPMs Closed</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-Overdue')}>PPMs Overdue</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-Technician')}>Technician PPMs</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-Site')}>Site PPMs</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-All')}>All PPMs</span></li>
                        </ul>
                    </li>

                    <li className={`${params.page.includes('Admin') ? 'active' : ''}`}>
                        <span className={'link'}>Administration</span>
                        <ul>
                            <li className={`${params.page === 'Admin Companies' ? 'active' : ''}`}>
                                <span className={'link'} onClick={() => params.setPage('Admin Companies')}>Manage Companies</span>
                            </li>
                            <li className={`${params.page === 'Admin Licenses' ? 'active' : ''}`}>
                                <span className={'link'} onClick={() => params.setPage('Admin Licenses')}>Manage Licenses</span>
                            </li>
                            <li className={`${params.page === 'Admin Users ' ? 'active' : ''}`}>
                                <span className={'link'} onClick={() => params.setPage('Admin Users ')}>Manage Users</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </>
    )
}
