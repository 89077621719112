import React from "react";
import {BranchData} from "../../../libs/models/Branch";
import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";
import {Pages} from "../../../libs/models/Types";
import {UserData} from "../../../libs/models/User";
import {MySelect} from "../../../libs/components/MySelect";
import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";
import {PPMData} from "../../../libs/models/PPM";
import {Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import {WorkOrderData} from "../../../libs/models/WorkOrder";
import moment from "moment/moment";
import Utilities from "../../../libs/Utilities";
import {MyDateInput} from "../../../libs/components/MyDateInput";
import {WorkOrderSite} from "../../../libs/models/WorkOrderSite";
import Requests from "../../../libs/Requests";

interface Search {
    branchId: string
    minDate?: string
    maxDate?: string
    technicianId?: string
    siteId?: string
}

export default function Reports(params: { page: Pages, users: UserData[], branches: BranchData[], sites: WorkOrderSite[] }) {
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [search, setSearch] = React.useState<Search>({branchId: staff.branchId})
    const handleSearch = (name: string, value: string) => {
        setSearch({...search, [name]: value})
    }

    const [canExport, setCanExport] = React.useState(false)
    const [reports, setReports] = React.useState<{ ppm?: PPMData[], orders?: WorkOrderData[] }>({})
    const searchReports = ({exportType}: { exportType?: 'pdf' | 'csv' }) => {
        if (params.page.includes("Reports-Work-Orders-")) {
            if (params.page === 'Reports-Work-Orders-Site' && search.siteId === "") {
                errorToast({message: 'Select a site name'})
            } else if (params.page === 'Reports-Work-Orders-Technician' && search.siteId === "") {
                errorToast({message: 'Select a technician name'})
            } else {
                showProgress("Loading site order reports, please wait")
                Requests.getWorkOrdersReport({
                    exportType  : exportType,
                    maxDate     : search.maxDate!!,
                    minDate     : search.minDate!!,
                    siteId      : search.siteId,
                    branchId    : search.branchId,
                    technicianId: search.technicianId,
                    reportType  : (params.page === 'Reports-Work-Orders-Site' ? 'Site' :
                            (params.page === 'Reports-Work-Orders-Technician' ? 'Technician' :
                                    (params.page === 'Reports-Work-Orders-Overdue' ? 'Overdue' :
                                            (params.page === "Reports-Work-Orders-Pending" ? 'Pending' :
                                                    (params.page === "Reports-Work-Orders-Upcoming" ? 'Upcoming' :
                                                            (params.page === 'Reports-Work-Orders-Open' ? 'Open' : 'Date')
                                                    )
                                            )
                                    )
                            )
                    ),
                })
                    .then((response) => {
                        hideProgress()
                        hideProgress()
                        if (response.data.status) {
                            setReports({orders: response.data.orders})
                            successToast({message: 'Work orders report loaded successfully'})
                            if (response.data.hasOwnProperty("path")) {
                                Utilities.downloadFile(response.data.path)
                            }
                        } else {
                            errorToast({message: response.data.message ?? 'Error loading work orders report, please retry'})
                        }
                    })
                    .catch(() => {
                        hideProgress()
                        errorToast({message: 'Error loading work orders report, please retry'})
                    })
            }
        } else if (params.page.includes("Reports-PPM-")) {
            if (params.page === 'Reports-PPM-Site' && search.siteId === "") {
                errorToast({message: 'Select a site name'})
            } else if (params.page === 'Reports-PPM-Technician' && search.siteId === "") {
                errorToast({message: 'Select a technician name'})
            } else {
                showProgress("Loading ppm reports, please wait")
                Requests.getPPMsReport({
                    exportType  : exportType,
                    maxDate     : search.maxDate!!,
                    minDate     : search.minDate!!,
                    siteId      : search.siteId,
                    branchId    : search.branchId,
                    technicianId: search.technicianId,
                    reportType  : (params.page === 'Reports-PPM-Site' ? 'Site' :
                            (params.page === 'Reports-PPM-Technician' ? 'Technician' :
                                    (params.page === 'Reports-PPM-Overdue' ? 'Overdue' :
                                            (params.page === "Reports-PPM-Closed" ? 'Closed' : "All")
                                    )
                            )
                    ),
                })
                    .then((response) => {
                        hideProgress()
                        hideProgress()
                        if (response.data.status) {
                            setReports({ppm: response.data.ppm})
                            successToast({message: 'PPM report loaded successfully'})
                            if (response.data.hasOwnProperty("path")) {
                                Utilities.downloadFile(response.data.path)
                            }
                        } else {
                            errorToast({message: response.data.message ?? 'Error loading PPM report, please retry'})
                        }
                    })
                    .catch(() => {
                        hideProgress()
                        errorToast({message: 'Error loading PPM report, please retry'})
                    })
            }
        }
    }

    React.useEffect(() => {
        setCanExport((reports.ppm !== undefined && reports.ppm.length > 0) || (reports.orders !== undefined && reports.orders.length > 0))
    }, [reports])

    React.useEffect(() => {
        if (params.page === 'Reports-Work-Orders-Date') {
            setSearch({branchId: staff.branchId, minDate: "2024-01-01", maxDate: Utilities.getToday()})
        } else if (params.page === 'Reports-Work-Orders-Technician' || params.page === 'Reports-PPM-Technician') {
            setSearch({branchId: staff.branchId, minDate: "2024-01-01", maxDate: Utilities.getToday(), technicianId: ''})
        } else if (params.page === 'Reports-Work-Orders-Site' || params.page === 'Reports-PPM-Site') {
            setSearch({branchId: staff.branchId, minDate: "2024-01-01", maxDate: Utilities.getToday(), siteId: ''})
        } else if (params.page.includes("Reports-Work-Orders-")) {
            setSearch({branchId: staff.branchId, minDate: "2024-01-01", maxDate: Utilities.getToday()})
        } else if (params.page.includes("Reports-PPM-")) {
            setSearch({branchId: staff.branchId, minDate: "2024-01-01", maxDate: Utilities.getToday()})
        }

        if (params.page.includes("Reports-Work-Orders-")) {
            setReports({orders: []})
        } else if (params.page.includes("Reports-PPM-")) {
            setReports({ppm: []})
        }
    }, [params.page])

    return (
        <>
            <div className={'filter_container'}>
                <div className={'filters'}>
                    <div>
                        <MySelect
                            name={"branchId"} value={search.branchId} placeholder={"Select branch name"} className={'mb-0'}
                            onChange={(name, value) => setSearch({...search, [name]: value as string})}
                            options={[
                                {text: 'Search all branches', value: ''},
                                ...params.branches
                                    .filter((branch) => branch.companyId === staff.companyId)
                                    .map((branch) => ({text: branch.branchName, value: branch.branchId}))
                            ]}/>
                    </div>

                    {
                        (search.minDate !== undefined && search.maxDate !== undefined) &&
                        <>
                            <div>
                                <MyDateInput name={'minDate'} placeholder={'Minimum date'} value={search.minDate}
                                             change={handleSearch} maxDate={search.maxDate} className={'mb-0'}/>
                            </div>
                            <div>
                                <MyDateInput name={'maxDate'} placeholder={'Maximum date'} value={search.maxDate}
                                             change={handleSearch} minDate={search.minDate} className={'mb-0'}/>
                            </div>
                        </>
                    }

                    {
                        search.technicianId !== undefined &&
                        <div>
                            <MySelect
                                name={"technicianId"} value={search.technicianId} placeholder={"Select technician name"} className={'mb-0'}
                                onChange={(name, value) => setSearch({...search, [name]: value as string})}
                                options={[
                                    {text: 'Select a technician', value: ''},
                                    ...params.users
                                        .filter((user) => user.branchId === search.branchId || search.branchId === "")
                                        .map((user) => ({text: `${user.lastName} ${user.firstName}`, value: user.userId}))
                                ]}/>
                        </div>
                    }

                    {
                        search.siteId !== undefined &&
                        <div>
                            <MySelect
                                name={"siteId"} value={search.siteId} placeholder={"Select site name"} className={'mb-0'}
                                onChange={(name, value) => setSearch({...search, [name]: value as string})}
                                options={[{text: 'Select a site', value: ''}, ...params.sites.map((site) => ({
                                    text : `${site.siteName}`,
                                    value: site.siteId
                                }))]}/>
                        </div>
                    }
                </div>

                <div className={'buttons'}>
                    <Button primary icon labelPosition='left' onClick={() => searchReports({exportType: 'pdf'})} disabled={!canExport} size={'mini'}>
                        <Icon name='file pdf'/>Print PDF
                    </Button>

                    <Button icon labelPosition='left' primary onClick={() => searchReports({exportType: 'csv'})} disabled={!canExport} size={'mini'}>
                        <Icon name='file excel'/>Export CSV
                    </Button>

                    <Button icon labelPosition='left' primary onClick={() => searchReports({})} size={'mini'}>
                        <Icon name='search'/>Search Data
                    </Button>
                </div>
            </div>

            <div className={'module_content'}>
                <div className={'table_container'}>
                    {
                        reports.orders !== undefined &&
                        <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell style={{width: '40px'}} textAlign={'center'}>No.</TableHeaderCell>
                                    <TableHeaderCell style={{width: '80px'}}>Order No</TableHeaderCell>
                                    <TableHeaderCell style={{width: '130px'}}>Branch Name</TableHeaderCell>
                                    <TableHeaderCell style={{width: '150px'}}>Site Name</TableHeaderCell>
                                    <TableHeaderCell style={{width: '130px'}}>Order Date</TableHeaderCell>
                                    <TableHeaderCell style={{width: '60px'}} textAlign={'center'}>Days</TableHeaderCell>
                                    <TableHeaderCell style={{width: '60px'}}>Level</TableHeaderCell>
                                    <TableHeaderCell style={{width: '140px'}}>Category</TableHeaderCell>
                                    <TableHeaderCell style={{width: '150px'}}>Technician</TableHeaderCell>
                                    <TableHeaderCell style={{width: '80px'}}>Status</TableHeaderCell>
                                </TableRow>
                            </TableHeader>

                            <TableBody>
                                {
                                    reports.orders.map((order, index) =>
                                        <TableRow key={order.workOrderId}>
                                            <TableCell style={{width: '40px'}} textAlign={'center'}>{index + 1}</TableCell>
                                            <TableCell style={{width: '80px'}}>{order.orderNo}</TableCell>
                                            <TableCell style={{width: '130px'}}>
                                                {params.branches.filter((branch) => branch.branchId === order.branchId)[0]?.branchName}
                                            </TableCell>
                                            <TableCell style={{width: '150px'}}>{order.siteName}</TableCell>
                                            <TableCell style={{width: '130px'}}>{moment(order.timeCreated).format("lll")}</TableCell>
                                            <TableCell style={{width: '60px'}} textAlign={'center'}>{order.days}</TableCell>
                                            <TableCell style={{width: '60px'}}>{order.workLevel}</TableCell>
                                            <TableCell style={{width: '140px'}}>
                                                {order.assetCategory === 'Other' ? order.assetCategoryOther : order.assetCategory}
                                            </TableCell>
                                            <TableCell style={{width: '150px'}}></TableCell>
                                            <TableCell style={{width: '80px'}}>{order.workStatus}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    }

                    {
                        reports.ppm !== undefined &&
                        <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell style={{width: '30px'}} textAlign={'center'}>No.</TableHeaderCell>
                                    <TableHeaderCell style={{width: '150px'}}>Branch Name</TableHeaderCell>
                                    <TableHeaderCell style={{width: '160px'}}>PPM Name</TableHeaderCell>
                                    <TableHeaderCell style={{width: '190px'}}>PPM Address</TableHeaderCell>
                                    <TableHeaderCell style={{width: '80px'}}>Next Service</TableHeaderCell>
                                    <TableHeaderCell style={{width: '50px'}} textAlign={'center'}>Days</TableHeaderCell>
                                    <TableHeaderCell style={{width: '70px'}}>Frequency</TableHeaderCell>
                                    <TableHeaderCell style={{width: '80px'}}>End Date</TableHeaderCell>
                                    <TableHeaderCell style={{width: '70px'}}>Status</TableHeaderCell>
                                </TableRow>
                            </TableHeader>

                            <TableBody>
                                {
                                    reports.ppm.map((ppm, index) =>
                                        <TableRow key={ppm.ppmId}>
                                            <TableCell style={{width: '30px'}} textAlign={'center'}>{index + 1}</TableCell>
                                            <TableCell style={{width: '150px'}}>{ppm.branchName}</TableCell>
                                            <TableCell style={{width: '160px'}}>{ppm.ppmName}</TableCell>
                                            <TableCell style={{width: '190px'}}>{ppm.ppmAddress}</TableCell>
                                            <TableCell style={{width: '80px'}}>{moment(ppm.nextDate).format("ll")}</TableCell>
                                            <TableCell style={{width: '50px'}} textAlign={'center'}>{ppm.daysToService}</TableCell>
                                            <TableCell style={{width: '70px'}}>{ppm.serviceFrequency}</TableCell>
                                            <TableCell style={{width: '80px'}}>{moment(ppm.endDate).format("ll")}</TableCell>
                                            <TableCell style={{width: '70px'}}>{ppm.ppmStatus}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    }
                </div>
            </div>
        </>
    )
}
