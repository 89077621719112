import {PPM} from "../../../../libs/models/PPM";
import React, {useState} from "react";
import {useToast} from "../../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../../libs/components/providers/ProgressProvider";
import FormButtons from "../../../../libs/components/FormButtons";
import {Button, Icon} from "semantic-ui-react";
import MyInput from "../../../../libs/components/MyInput";
import FormTextArea from "../../../../libs/components/FormTextArea";
import {MySelect} from "../../../../libs/components/MySelect";
import {MyDateInput} from "../../../../libs/components/MyDateInput";
import Utilities from "../../../../libs/Utilities";
import moment from "moment";
import Requests from "../../../../libs/Requests";

export default function SavePPM(params: { ppm: PPM, update: (ppm: PPM) => void, close?: () => void }) {
    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [ppm, setPPm] = useState<PPM>(params.ppm)
    const handleChange = (name: string, value: string) => {
        setPPm({...ppm, [name]: value})
    }

    const savePPM = () => {
        if (ppm.ppmName.trim().length < 3) {
            errorToast({message: 'Please enter a valid PPM name.'})
        } else if (ppm.ppmAddress.trim().length < 3) {
            errorToast({message: 'Please enter a valid PPM address.'})
        } else if (ppm.description.trim().length < 10) {
            errorToast({message: 'Please enter a valid PPM description.'})
        } else if (ppm.startDate === "") {
            errorToast({message: 'Select a valid PPM start date of maintenance'})
        } else if (ppm.endDate === "") {
            errorToast({message: 'Select a valid PPM end date of maintenance'})
        } else if (moment(ppm.startDate).isAfter(moment(ppm.endDate))) {
            errorToast({message: 'Start date of maintenance cannot be before end date'})
        } else {
            showProgress("Saving Preventive Planned Maintenance, please wait")
            Requests.savePPM({
                branchId        : ppm.branchId,
                description     : ppm.description.trim(),
                endDate         : ppm.endDate,
                pmId            : ppm.ppmId,
                ppmAddress      : ppm.ppmAddress.trim(),
                ppmName         : ppm.ppmName.trim(),
                serviceFrequency: ppm.serviceFrequency,
                startDate       : ppm.startDate
            })
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: 'Preventive Planned Maintenance has been saved successfully'})
                        params.update({...ppm, ppmId: response.data.ppmId})
                    } else {
                        errorToast({message: response.data.message ?? 'Could not save Preventive Planned Maintenance, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not save Preventive Planned Maintenance, please retry'})
                })
        }
    }

    React.useEffect(() => {
        setPPm(params.ppm)
    }, [params.ppm])

    return (
        <div className="form_container">
            <div className={'form_input'}>
                <MyInput name={'ppmName'} placeholder={'Enter PPM name'} label={'Preventive Planned Maintenance Name'}
                         value={ppm.ppmName} onChange={handleChange}/>

                <MyInput name={'ppmAddress'} placeholder={'Enter PPM Address'} label={'Preventive Planned Maintenance Address'}
                         value={ppm.ppmAddress} onChange={handleChange}/>

                <FormTextArea name={'description'} onChange={handleChange} rows={3} value={ppm.description}
                              placeholder={"Enter description of Preventive Planned Maintenance"} label={'Preventive Planned Maintenance Description'}/>

                <MySelect name={'serviceFrequency'} placeholder={'Select PPM Service Frequency'}
                          value={ppm.serviceFrequency} label={'Preventive Planned Maintenance Service Frequency'}
                          onChange={(name, value) => setPPm({...ppm, [name]: value as string})}
                          options={[
                              {text: 'After every month', value: 'Monthly'},
                              {text: 'After every 3 months', value: 'Quarterly'},
                              {text: 'After every 6 months', value: 'Bi Annually'},
                              {text: 'After every 12 Months', value: 'Annually'},
                          ]}/>

                <MyDateInput name={'startDate'} placeholder={'Select start date'} label={'Start date of maintenance'}
                             value={ppm.startDate} change={handleChange} maxDate={ppm.endDate} minDate={Utilities.getToday()}/>

                <MyDateInput name={'endDate'} placeholder={'Select end date'} label={'End date of maintenance'}
                             value={ppm.endDate} change={handleChange} minDate={ppm.startDate}
                             className={'mb-0'}/>
            </div>

            {
                params.close === undefined ?
                    <div className={'form_buttons'}>
                        <div className={'row mx-0'}>
                            <div className={'col-12 px-0'}>
                                <Button icon labelPosition='left' primary fluid={true} onClick={savePPM} size={'small'}>
                                    <Icon name='save'/>{'Save PPM'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    :
                    <FormButtons
                        left={{text: 'Close Window', click: () => params.close && params.close()}}
                        right={{click: savePPM, text: 'Update PPM'}}/>

            }
        </div>
    )
}
