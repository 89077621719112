export type Pages =
    'Home' |
    'Admin Companies' | 'Admin Licenses' | 'Admin Users ' |
    'Assets' |
    'Create WorkOrders' | 'Approve WorkOrders' | 'WorkOrders-Approved' | 'WorkOrders-Open' | 'WorkOrders-InProgress' | 'WorkOrders-Closed' | 'WorkOrders-All' |
    'Create PPM' | 'View PPMs' |
    'Reports-Work-Orders-Date' | 'Reports-Work-Orders-Open' | 'Reports-Work-Orders-Upcoming' | 'Reports-Work-Orders-Pending' |
    'Reports-Work-Orders-Overdue' | 'Reports-Work-Orders-Technician' | 'Reports-Work-Orders-Site' |
    'Reports-PPM-Closed' | 'Reports-PPM-Overdue' | 'Reports-PPM-Technician' | 'Reports-PPM-Site' | 'Reports-PPM-All'

export const GroupRoles = {
    'CompanyView': 'View Companies',
    'CompanyEdit': 'Edit Companies',
    'CompanyAdd' : 'Create New Companies',

    'BranchesEdit': 'Edit Branches',
    'BranchesAdd' : 'Add Branches',
    'BranchesView': 'View Branches',

    'LicensesView': 'View Licenses',
    'LicenseEdit' : 'Edit Licenses',
    'LicenseAdd'  : 'Add Licenses',

    'GroupsView': 'View Groups',
    'GroupEdit' : 'Edit Groups',
    'GroupAdd'  : 'Add Groups',

    'UsersView'    : 'View Users',
    'UserEdit'     : 'Edit Users',
    'UserAdd'      : 'Add Users',
    'PasswordReset': 'Reset User Passwords',

    'WorkOrdersView'   : 'View WorkOrders',
    'WorkOrdersAdd'    : 'Create WorkOrders',
    'WorkOrdersEdit'   : 'Edit WorkOrders',
    'WorkOrdersApprove': 'Approve or Reject WorkOrders',

    'CreatePPM'       : 'Create Preventive Planned Managements',
    'EditPPM'         : 'Edit Preventive Planned Managements',
    'ViewPPM'         : 'View Preventive Planned Managements',
    'CreatePPMService': 'Create Preventive Planned Management Service',

    'CreateReports': 'Create Reports',
}

export const RoleGroups: {
    name: string,
    permissions: (keyof typeof GroupRoles)[]
}[] = [
    {name: 'Companies and Branches Management', permissions: ['CompanyView', 'CompanyEdit', 'CompanyAdd', 'BranchesView', 'BranchesEdit', 'BranchesAdd']},
    {name: 'Users and Groups Management', permissions: ['GroupsView', 'GroupAdd', 'GroupEdit', 'UsersView', 'UserAdd', 'UserEdit', 'PasswordReset']},
    {name: 'Licenses Management', permissions: ['LicensesView', 'LicenseEdit', 'LicenseAdd']},
    {name: 'Work Orders', permissions: ['WorkOrdersAdd', 'WorkOrdersEdit', 'WorkOrdersApprove', 'WorkOrdersView']},
    {name: 'Preventive Planned Managements', permissions: ['CreatePPM', "EditPPM", 'ViewPPM', 'CreatePPMService']},
    {name: 'Create and Export Reports', permissions: ['CreateReports']}
]
